<template>
  <b-card
    class="rounded-0"
    no-body
    :style="$store.state.appConfig.layout.postMode === 'default' ? 'margin-bottom: 0px; max-height: 600px;' : 'background-color: inherit; border: none; margin: 0; box-shadow: none;'"
  >

    <template v-if="$store.state.appConfig.layout.postMode === 'drudge'">
      <!-- <a
        :id="post.id"
        :href="`${getBaseUrl}/go/${post.slug}`"
        target="_blank"
        @click.prevent="handleClick"
        @click.middle.prevent="handleClick"
      >
        {{ post.title }}
      </a> -->
      <b-card-body style="padding: 0px 10px; background-color: white; ">
        <b-card-text
          v-b-toggle="'collapse-' + post.slug + '-drudge'"
          class="page-link-local"
          :style="`font-size: ${fontSizeTitle}; padding-top: 10px;`"
        >
          <div>
            {{ $filters.limitTo(post.title, titleLength) }}
          </div>
        </b-card-text>

        <template v-if="$can('update', 'Post')">
          <router-link
            v-if="$can('update', post)"
            class="nav-link"
            :to="`/authors/post/edit/${post.id}`"
            style="margin: 0px; padding: 0px;"
          >
            <a class="mc-btn-action mc-btn-unlocked">
              <i class="fa fa-pencil" />
            </a>
          </router-link>
          <a
            v-else
            class="mc-btn-action mc-btn-locked"
          >
            <i class="fa fa-lock" />
          </a>
        </template>

        <template v-if="$can('delete', 'Post')">
          <div
            v-if="$can('delete', post)"
            class="nav-link"
            style="margin: 0px; padding: 0px;"
            @click="showMsgBoxOne"
          >
            <a class="mc-btn-trash mc-btn-unlocked">
              <i class="fa fa-trash" />
            </a>
          </div>
        </template>

        <b-collapse
          :id="`collapse-${post.slug}-drudge`"
          class="mt-1"
        >
          <b-card-text
            class="post-description"
            :style="`font-size: ${fontSizeDesc};`"
          >
            <div style="overflow-wrap: anywhere;">
              {{ $filters.limitTo(post.description, descriptionLength) }}
            </div>

            <div class="card__share">
              <b-button
                v-if="post !== undefined && post.slug !== undefined"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                :to="getMoreDict(post)"
                squared
                variant="light"
                size="sm"
                style="margin: 10px 0 0 0; color: #352a81;"
              >
                more
                <feather-icon
                  icon="ArrowRightIcon"
                  size="12"
                  style="color: #352a81;"
                />
              </b-button>
            </div>
          </b-card-text>
        </b-collapse>

      </b-card-body>

    </template>

    <template v-else>
      <div
        class="overflow-hidden mb-auto"
        style="height: 100%"
      >
        <h3
          v-if="post.label && post.label !== ''"
          class="tagbanner"
        >
          {{ post.label }}
        </h3>

        <template v-if="$can('update', 'Post')">
          <router-link
            v-if="$can('update', post)"
            class="nav-link"
            :to="`/authors/post/edit/${post.id}`"
            style="margin: 0px; padding: 0px;"
          >
            <a class="mc-btn-action mc-btn-unlocked">
              <i class="fa fa-pencil" />
            </a>
          </router-link>
          <a
            v-else
            class="mc-btn-action mc-btn-locked"
          >
            <i class="fa fa-lock" />
          </a>
        </template>

        <template v-if="$can('delete', 'Post')">
          <div
            v-if="$can('delete', post)"
            class="nav-link"
            style="margin: 0px; padding: 0px;"
            @click="showMsgBoxOne"
          >
            <a class="mc-btn-trash mc-btn-unlocked">
              <i class="fa fa-trash" />
            </a>
          </div>
        </template>

        <b-row
          no-gutters
          class="post-row"
          style="min-height: 100%"
        >

          <b-col
            v-if="imageExists"
            :class="imageClass"
          >
            <b-card-img
              v-image-fall-back
              v-b-toggle="'collapse-' + post.slug"
              :src="imageUrl"
              alt="Image"
              class="image-tmp rounded-0 page-link-local"
              :style="wide ? 'Xmin-height: 300px;' : 'max-height: 140px;'"
            />
          </b-col>

          <b-col
            :class="postClass"
          >
            <div>
              <span
                v-for="(category, index) in post.categories"
                :key="index"
                class="post-category"
              >
                <router-link
                  :to="{ path: `/${category}` }"
                  class="text-muted"
                  style="margin-right: 0px; font-size: 0.7rem;"
                >
                  {{ category }}
                </router-link>
              </span>
              |
              <span
                v-if="post.team !== ''"
                style="padding: 0 5px 0 5px; display: inline-block; text-transform: uppercase;"
              >
                <router-link
                  :to="{ path: `/${post.categories[0]}/${post.team.replace(/\s/g, '')}` }"
                  class="text-muted"
                  style="margin-right: 5px; font-size: 0.7rem;"
                >
                  {{ post.team }}
                </router-link>
                |
              </span>
              <a
                class="text-muted"
                style="padding: 0 5px 0 5px; display: inline-block; font-size: 0.7rem;"
                :href="getRssLink(post)"
                target="_blank"
              >
                <feather-icon
                  icon="RssIcon"
                />

                {{ urlhostname() }}
              </a>
              <span
                v-if="post.author !== null && post.author !== undefined"
              >
                |
              </span>
              <span
                v-if="post.author !== null && post.author !== undefined"
                class="small text-muted"
                style="padding: 0 5px 0 0px;"
              >
                <feather-icon icon="UserIcon" />
                <a
                  :href="`${getBaseUrl}/authors/${post.author.username}`"
                  class="text-muted"
                >
                  {{ post.author.username }}
                </a>
              </span>
            </div>

            <b-card-body style="padding: 10px 15px 20px 15px;">
              <b-card-text
                v-b-toggle="'collapse-' + post.slug"
                class="post-title page-link-local"
                :style="`font-size: ${fontSizeTitle};`"
              >
                <div>
                  {{ $filters.limitTo(post.title, titleLength) }}
                </div>
              </b-card-text>

              <b-collapse
                :id="`collapse-${post.slug}`"
                :visible="!collapsed && ($store.getters['app/currentBreakPoint'] !== 'xs' && $store.getters['app/currentBreakPoint'] !== 'sm' && $store.getters['app/currentBreakPoint'] !== 'md')"
                class="mt-1"
              >
                <b-card-text
                  class="post-description"
                  :style="`font-size: ${fontSizeDesc};`"
                >
                  <div style="overflow-wrap: anywhere;">
                    {{ $filters.limitTo(post.description, descriptionLength) }}
                  </div>

                  <!-- <div class="card__share">
                    <span :class="isActive ? 'card__social--active' : 'card__social'">
                      <a class="share-icon facebook" href="#"><span class="fa fa-facebook"></span></a>
                      <a class="share-icon twitter" href="#"><span class="fa fa-twitter"></span></a>
                      <a class="share-icon googleplus" href="#"><span class="fa fa-google-plus"></span></a>
                    </span>
                    <a id="share" class="share-toggle share-icon" @click.prevent="shareClick"></a>
                  </div> -->
                  <div class="card__share">
                    <b-button
                      v-if="post !== undefined && post.slug !== undefined"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      :to="getMoreDict(post)"
                      squared
                      variant="light"
                      size="sm"
                      style="margin: 10px 0 0 0; color: #352a81;"
                    >
                      more
                      <feather-icon
                        icon="ArrowRightIcon"
                        size="12"
                        style="color: #352a81;"
                      />
                    </b-button>
                  </div>
                </b-card-text>
              </b-collapse>
            </b-card-body>

          </b-col>

        </b-row>

      </div>
      <div
        class="mt-auto"
        style="background: white;"
      >

        <span
          class="d-flex justify-content-between text-muted"
          style="padding: 7px 10px 7px 10px;"
        >
          <div class="d-flex align-items-center blog-detail-wrapper">
            <div class="d-flex align-items-center mr-1">
              <b-link class="mr-25">
                <div
                  v-if="$can('like', post)"
                >
                  <feather-icon
                    v-if="doesUserLike"
                    icon="ThumbsUpIcon"
                    size="20"
                    class="text-body"
                    fill="black"
                    @click="unlikePost"
                  />
                  <feather-icon
                    v-else
                    icon="ThumbsUpIcon"
                    size="20"
                    class="text-body"
                    @click="likePost"
                  />
                </div>
                <div v-else>
                  <feather-icon
                    icon="ThumbsUpIcon"
                    size="20"
                    class="text-body"
                    @click="showLoginBox"
                  />
                </div>
              </b-link>
              <b-link>
                <div class="medium text-muted">
                  {{ numberOfLikes }}
                </div>
              </b-link>
            </div>
            <div class="d-flex align-items-center mr-1">
              <b-link class="mr-25">
                <feather-icon
                  size="20"
                  icon="EyeIcon"
                  class="text-body"
                />
              </b-link>
              <b-link>
                <div class="medium text-muted">
                  {{ post.clicks }}
                </div>
              </b-link>
            </div>
            <!-- <div class="d-flex align-items-center mr-1">
              <b-link class="mr-25">
                <feather-icon
                  size="20"
                  icon="MessageCircleIcon"
                  class="text-body"
                />
              </b-link>
              <b-link>
                <div class="medium text-muted">
                  {{ post.comments }}
                </div>
              </b-link>
            </div> -->
          </div>

          <!-- dropdown -->
          <div class="blog-detail-share">
            <span style="padding: 0 5px 0 5px;">
              <!-- <feather-icon
                size="20"
                icon="CalendarIcon"
              /> -->
              {{ formatDate(new Date(post.pubDate)) }}
            </span>

            <b-dropdown
              :id="'dropdown-' + post._id"
              variant="link"
              toggle-class="p-0"
              no-caret
              right
              menu-class="wrap-dropdown"
            >
              <template #button-content>
                <feather-icon
                  size="20"
                  icon="Share2Icon"
                  class="text-body"
                />
              </template>
              <b-dropdown-item
                v-for="(item, key) in social"
                :key="key"
                :href="item.link"
                style="z-index: 100;"
              >
                <feather-icon
                  :icon="item.icon"
                  size="20"
                />
                {{ item.name }}
              </b-dropdown-item>
            </b-dropdown>
          </div>
          <!--/ dropdown -->
        </span>

      </div>
    </template>

  </b-card>
</template>

<script>
import {
  reactive, toRefs, ref, computed,
} from '@vue/composition-api'

import {
  BCard,
  BCardText,
  BCardBody,
  // BCardFooter,
  BCardImg,
  BRow,
  BCol,
  BCollapse,

  BButton,
  BLink,
  BDropdown,
  BDropdownItem,

  VBToggle,
} from 'bootstrap-vue'

import { $themeConfig } from '@themeConfig'

import Ripple from 'vue-ripple-directive'

// import moment from 'moment'

// import router from '@/router'
import store from '@/store'

import { isUserLoggedIn } from '@/auth/utils'

import * as PostService from '@/services/PostService'
import formatDate from '../utils/formatDate'

export default {
  components: {
    // Card components
    BCard,
    BCardText,
    BCardBody,
    // BCardFooter,
    BCardImg,
    BRow,
    BCol,
    BCollapse,
    BButton,
    BLink,
    BDropdown,
    BDropdownItem,
  },

  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },

  props: {
    post: {
      type: Object,
      default: null,
    },
    collapsed: {
      type: Boolean,
      default: false,
    },
    minimal: {
      type: Boolean,
      default: false,
    },
    wide: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, context) {
    const isLoggedIn = isUserLoggedIn()
    const boxOne = ref('')

    const {
      social,
    } = $themeConfig

    const userId = computed(() => {
      const userData = JSON.parse(localStorage.getItem('userData'))
      if (userData) {
        // event.doesUserLike = props.post.likes.includes(userData._id)

        /* eslint no-underscore-dangle: ["error", { "allow": ["foo_", "_id"] }] */
        return userData._id
      }
      return 'n/a'
    })

    const event = reactive({
      getBaseUrl: context.root.$baseFrontendUrl,
      getBaseUrlWithPort: context.root.$baseFrontendUrlWithPort,
      isActive: false,
      imageError: false,
      goLink: '',
      numberOfLikes: props.post.likes.length,
      doesUserLike: props.post.likes.includes(userId.value),
      titleLength: 64,
      descriptionLength: 96,
    })

    const postAuthorId = computed(() => {
      if (props.post.author !== undefined && props.post.author !== null) {
        /* eslint no-underscore-dangle: ["error", { "allow": ["foo_", "_id"] }] */
        return props.post.author._id
      }
      return 'n/a'
    })

    const canUpdate = computed(() => context.root.$can('update', props.post))
    const canLike = computed(() => context.root.$can('like', props.post))
    const fontSizeTitle = computed(() => store.state.appConfig.layout.fontSizeTitle)
    const fontSizeDesc = computed(() => store.state.appConfig.layout.fontSizeDesc)

    const handleClick = e => {
      let url = e.target.href

      if (url.endsWith('/')) {
        url = url.slice(0, url.length - 1)
      }

      if (e.button === 0) {
        window.open(url, '_blank')
      } else if (e.button === 1) {
        // Skip middle click, handled by browser.
      } else if (e.button === 2) {
        window.open(url, '_blank')
      }

      e.preventDefault()
    }

    function shareClick() {
      event.isActive = !event.isActive
    }

    function domainFromUrl(url) {
      let result = ''
      // eslint-disable-next-line
      const match = url.match(/^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n\?\=]+)/im)
      if (match != null) {
        [, result] = match
        // eslint-disable-next-line
        const newmatch = result.match(/^[^\.]+\.(.+\..+)$/)
        if (newmatch != null) {
          [, result] = newmatch
        }
      }
      return result
    }

    function urlhostname() {
      try {
        const urlObject = new URL(props.post.source)
        // return urlObject.hostname.replace(/^[^.]+\./g, '')
        return domainFromUrl(urlObject.hostname)
      } catch (error) {
        return 'newsapi'
      }
    }

    function likePost() {
      PostService.likePost(props.post.id)
        .then(response => {
          event.numberOfLikes = response.data.post.likes.length
          props.post.likes = response.data.post.likes // eslint-disable-line no-param-reassign
          event.doesUserLike = response.data.post.likes.includes(userId.value)
        })
    }

    function unlikePost() {
      PostService.unlikePost(props.post.id)
        .then(response => {
          event.numberOfLikes = response.data.post.likes.length
          props.post.likes = response.data.post.likes // eslint-disable-line no-param-reassign
          event.doesUserLike = response.data.post.likes.includes(userId.value)
        })
    }

    function showLoginBox() {
      context.root.$bvModal
        .msgBoxOk('Sign in required.', {
          centered: true,
        })
    }

    function showMsgBoxOne() {
      context.root.$bvModal
        .msgBoxConfirm('Are you sure?', {
          cancelVariant: 'outline-secondary',
        })
        .then(value => {
          if (value) {
            PostService.deletePost(props.post.id)
            // .catch(error => {
            //   console.log(error)
            // })
            // router.push(`/authors/post/edit/${props.post.id}`)
          }
        })
    }

    const imageUrl = computed(() => props.post.images[0])
    const imageExists = computed(() => {
      if (Object.keys(props.post).length === 0) {
        return false
      }
      return props.post.images.length > 0 && props.post.images[0] != null && props.post.images[0] !== ''
    })
    const isBreakPointPhone = computed(() => store.getters['app/currentBreakPoint'] === 'xs' || store.getters['app/currentBreakPoint'] === 'sm' || store.getters['app/currentBreakPoint'] === 'md')

    const imageClass = computed(() => {
      if (props.minimal) {
        return 'col-lg-4 col-md-4 col-sm-4 col-4'
      }
      if (props.wide) {
        return 'col-lg-4 col-md-4 col-sm-12 col-12'
      }
      return 'col-lg-12 col-md-4 col-sm-4 col-4'
    })

    const postClass = computed(() => {
      if (props.minimal) {
        return 'col-lg-8 col-md-8 col-sm-8 col-8'
      }
      if (imageExists) {
        if (props.wide) {
          return 'col-lg-8 col-md-8 col-sm-12 col-12'
        }
        return 'col-lg-12 col-md-8 col-sm-8 col-8'
      }
      return 'col-lg-12 col-md-12 col-sm-12 col-12'
    })

    const socialShareIcons = {
      FacebookIcon: { name: 'Facebook' },
      TwitterIcon: { name: 'Twitter' },
      InstagramIcon: { name: 'Instagram' },
      MailIcon: { name: 'Email' },
    }

    function getGoLink(post) {
      if (event.goLink !== '') {
        return event.goLink
      }
      if (post === undefined) {
        return ''
      }
      let goPath = `${event.getBaseUrlWithPort}/go`
      if (post.sport !== undefined && post.sport !== '') {
        goPath += `/${post.sport}`
      }
      if (post.team !== undefined && post.team !== '') {
        // Remove white space in team names: 'red sox' -> 'redsox'
        goPath += `/${post.team.replace(/\s/g, '')}`
      }
      goPath += `/${post.slug}`

      event.goLink = goPath

      return goPath
    }

    function getRssLink(post) {
      return post.source
    }

    function getMoreDict(post) {
      if (post.categories !== undefined && post.categories.length > 0) {
        if (post.team !== undefined && post.team !== '') {
          return {
            name: 'post-detail-sport-team-slug',
            path: `/post/${post.categories[0]}/${post.team}/${post.slug}`,
            params: {
              team: post.team,
              sport: post.categories[0],
              slug: post.slug,
              post: JSON.stringify(post),
            },
          }
        }

        return {
          name: 'post-detail-sport-slug',
          path: `/post/${post.categories[0]}/${post.slug}`,
          params: {
            sport: post.categories[0],
            slug: post.slug,
            post: JSON.stringify(post),
          },
        }
      }

      return {
        name: 'post-detail-slug',
        path: `/post/${post.slug}`,
        params: {
          slug: post.slug,
          post: JSON.stringify(post),
        },
      }
    }

    return {
      isLoggedIn,
      ...toRefs(event),
      formatDate,
      handleClick,
      shareClick,
      canUpdate,
      canLike,
      postAuthorId,
      userId,
      fontSizeTitle,
      fontSizeDesc,
      showMsgBoxOne,
      showLoginBox,
      likePost,
      unlikePost,
      boxOne,
      urlhostname,
      imageUrl,
      socialShareIcons,
      social,
      getGoLink,
      getRssLink,
      isBreakPointPhone,
      imageExists,
      getMoreDict,
      imageClass,
      postClass,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/_variables';
@import '@core/scss/vue/pages/page-blog.scss';

$border: #ebebeb;
$text: #444;
$catpad: 15px;

@mixin transition($args...) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}

.page-link-local, .page-link-local:visited, .page-link-local:hover, .page-link-local:active, .page-link-local:focus {
  outline: none !important;
}

.image-tmp {
  height: 100%;

  // Sets the maximum height to higher value when the card changes
  // from picture on top to picture on left side.
  @media screen and (max-width: 992px) {
    max-height: 250px;
  }

  overflow: hidden;
  transition: transform 0.5s ease;
  transition-property: transform;
  transition-duration: 0.5s;
  transition-timing-function: ease;
  transition-delay: 0s;
  backface-visibility: hidden;
  object-fit: cover;
}

.image-tmp:hover {
  transform: scale(1.1);
}

.post-category {
  display: inline-block;
  text-transform: uppercase;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.0625rem;
  margin: $catpad 0.0rem 0.0rem $catpad;

  @media screen and (max-width: 992px) {
    font-size: 0.7em;
    margin: 10px 0 0 $catpad;
  }
}

.post-title {
  @include transition(color 0.3s ease);
  font-size: 1.2rem;
  line-height: 1.15;
  font-weight: 500;
  overflow: hidden;
  color: $primary;

  @media screen {
    height: 100%;
  }

  @media screen and (max-width: 992px) {
    font-size: 1.1em;
  }
}

.post-description {
  overflow-wrap: anywhere;
  @include transition(color 0.3s ease);
  font-size: 1.2rem;
  line-height: 1.15;
  color: $text;
  font-weight: 400;
  width: 90wv;
  padding: 0;
}

.post-info {
  text-align: center;
  color: #929599;
  font-size: 11px;
  // width: 100%;
  // // position: relative;
  // bottom: 5px;
  //padding: 30px 0 0 0;

  position: absolute;
  bottom: 5px;
  right: 10px;
  left: 10px;
  /* this is the key */
  max-height:100%;
}
.post-info-pic {
  display: inline-block;
  text-align: center;
  color: #929599;
  padding-top: 20px;
  font-size: 11px;
  width: 100%;
  position: absolute;
  bottom: 5px;
  margin-top: 10px;
  padding-left: 100px;
}

.post-time {
  // line-height: 1;
  // z-index: 10;
  // text-align: left;
  // // position: sticky;
  // // padding: 10px 0 0 0;
  // position: absolute;
  // bottom: -5px;
  // left: 10px;

  line-height: 1;
  z-index: 10;
  text-align: right;
  // position: sticky;
  // padding: 10px 0 0 0;
  position: absolute;
  bottom: 11px;
  right: 10px;
}

.post-author {
  // line-height: 1;
  // z-index: 10;
  // text-align: right;
  // // position: sticky;
  // // padding: 10px 0 0 0;
  // position: absolute;
  // bottom: 11px;
  // right: 10px;
  color: white;
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 5px 10px 0 0;
 }

.post-row {
  // Fixes the compressed view where the images don't fit the
  // height of the card. If applied to all sizes, even when image
  // is above the text, then the image sizes will be different for
  // different cards.

  // Removed the limit to screen width that we had on regular Posts
  // @media screen and (max-width: 992px) {
  min-height: 90%;
  // }
}

.post-shares {
  text-align: center;
  padding: 5px 0 5px 0;
}

.rounded-card {
  border-radius: 5px;
}

.mc-btn-action {
  position: absolute;
  right: 8px;
  top: 8px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  border: 2px solid;
  width: 32px;
  height: 32px;
  line-height: 27px;
  text-align: center;
  background-color: $primary;
  color: $secondary;
  cursor: pointer;
  z-index: 2;
  //.mc-transtition();
}

.mc-btn-trash {
  position: absolute;
  right: 45px;
  top: 8px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  border: 2px solid;
  width: 32px;
  height: 32px;
  line-height: 27px;
  text-align: center;
  background-color: #c50505;
  color: $secondary;
  cursor: pointer;
  z-index: 2;
  //.mc-transtition();
}

.mc-btn-like {
  //position: absolute;
  //left: 10px;
  //top: 8px;
  //-webkit-border-radius: 50%;
  //-moz-border-radius: 50%;
  //border-radius: 50%;
  //border: 2px solid;
  width: 32px;
  height: 32px;
  line-height: 27px;
  text-align: center;
  //background-color: #c50505;
  //color: $secondary;
  cursor: pointer;
  z-index: 20;
  //.mc-transtition();
}

.mc-btn-locked:hover {
  color: red;
}

.mc-btn-unlocked:hover {
  color: rgb(13, 175, 13);
}

a {
  text-decoration: none;
  color: #3498db;
}

.dropdown-menu #zzz {
  max-width: 2rem !important;
  white-space: normal;
  min-width:inherit;
}

.wrap-dropdown {
  max-width: 2rem !important;
  white-space: normal;
  min-width:inherit;
}
.dropdown-item {
  max-width: 2rem !important;
  white-space: normal;
  min-width:inherit;
}

.card__share {
  float: center;
  position: relative;
  margin-top: 0px;
  flex: none;
  text-align:center;
}

</style>
