<template>
  <div>
    <h6
      v-if="titleFirst !== '' || titleSecond !== ''"
      class="kf_hd1"
    >
      <span><strong class="titleClass">{{ titleFirst }}</strong> {{ titleSecond }}</span>
    </h6>

    <b-col
      lg="12"
      md="12"
      sm="12"
      cols="12"
      class="overflow-hidden rounded-0"
      style="margin-bottom: 5px; padding-right: 0; padding-left: 0;"
    >

      <app-collapse
        v-if="collapsible"
      >
        <app-collapse-item
          v-for="post in posts"
          :key="post.id"
          :title="$filters.limitTo(post.title, titleLength)"
          title-class="titleClass"
          :title-style="`font-size: ${fontSizeTitle}; font-weight: 700;`"
          :style="`font-size: ${fontSizeDesc};`"
        >
          <span
            v-if="displaySports"
            slot="b-badge"
          >
            <b-badge
              v-for="category in post.categories"
              :key="category.id"
              :variant="`light-${getColor(category)}` || 'light-primary'"
              pill
              class="badge-round"
            >
              {{ category }}
            </b-badge>
          </span>

          <div style="overflow-wrap: anywhere;">
            {{ $filters.limitTo(post.description, descriptionLength) }}
          </div>

          <div class="card__share">
            <b-button
              v-if="post !== undefined && post.slug !== undefined"
              :to="getMoreDict(post)"
              squared
              variant="light"
              size="sm"
              style="margin: 10px 0 0 0;"
            >
              more
              <feather-icon
                icon="ArrowRightIcon"
                size="12"
                style="color: blue;"
              />
            </b-button>
          </div>
        </app-collapse-item>
      </app-collapse>

      <b-list-group
        v-else
      >
        <b-list-group-item
          v-for="post in posts"
          :key="post.id"
          :to="getMoreDict(post)"
          class="d-flex align-items-center post-items"
          :style="`font-size: ${fontSizeTitle};`"
        >
          <b-img
            v-if="displaySports"
            class="feather feather-user"
            :src="getSportIcon(post)"
            width="30px"
            style="padding-right: 15px; flex-shrink: 0;"
          />
          <p style="margin-bottom: 0; justify-content: left; max-width: 75vw; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
            {{ post.title }}
          </p>
        </b-list-group-item>
      </b-list-group>

    </b-col>
  </div>
</template>

<script>
/* eslint-disable global-require */
import {
  reactive, toRefs,
} from '@vue/composition-api'
import {
  BCol,
  BButton,
  BImg,
  BListGroup,
  BListGroupItem,
} from 'bootstrap-vue'

import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

export default {
  components: {
    BCol,
    BButton,
    BImg,
    BListGroup,
    BListGroupItem,

    AppCollapse,
    AppCollapseItem,
  },

  props: {
    posts: {
      type: Array,
      default: () => [],
      // validator: (prop) => prop.every(e => typeof e === 'string'),
    },
    titleFirst: {
      type: String,
      default: '',
    },
    titleSecond: {
      type: String,
      default: '',
    },
    displaySports: {
      type: Boolean,
      default: false,
    },
    collapsible: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, context) {
    const event = reactive({
      getBaseUrl: context.root.$baseFrontendUrl,
      colors: {
        soccer: 'secondary',
        nhl: 'info',
        nba: 'danger',
        nfl: 'warning',
        mlb: 'success',
      },
      icons: {
        soccer: require('@/assets/images/icons/sports/soccer.svg'),
        nhl: require('@/assets/images/icons/sports/hockey.svg'),
        nba: require('@/assets/images/icons/sports/basketball.svg'),
        nfl: require('@/assets/images/icons/sports/football.svg'),
        mlb: require('@/assets/images/icons/sports/baseball.svg'),
        blank: require('@/assets/images/icons/sports/blank.svg'),
      },
      titleLength: 70,
      descriptionLength: 96,
    })

    function getColor(name) {
      if (name in event.colors) {
        return event.colors[name]
      }
      return 'primary'
    }

    const handleClick = e => {
      let url = e.target.href

      if (url.endsWith('/')) {
        url = url.slice(0, url.length - 1)
      }

      if (e.button === 0) {
        window.open(url, '_blank')
      } else if (e.button === 1) {
        // Skip middle click, handled by browser.
      } else if (e.button === 2) {
        window.open(url, '_blank')
      }

      e.preventDefault()
    }

    // const fontSizeTitle = computed(() => store.state.appConfig.layout.fontSizeTitle)
    // const fontSizeDesc = computed(() => store.state.appConfig.layout.fontSizeDesc)
    const fontSizeTitle = '1.0rem'
    const fontSizeDesc = '1.0rem'

    function getSportIcon(post) {
      if (post.categories !== undefined && post.categories.length > 0) {
        return event.icons[post.categories[0]]
      }
      return event.icons.blank
    }

    function getMoreDict(post) {
      if (post.categories !== undefined && post.categories.length > 0) {
        if (post.team !== undefined && post.team !== '') {
          return {
            name: 'post-detail-sport-team-slug',
            path: `/post/${post.categories[0]}/${post.team}/${post.slug}`,
            params: {
              team: post.team,
              sport: post.categories[0],
              slug: post.slug,
              post: JSON.stringify(post),
            },
          }
        }

        return {
          name: 'post-detail-sport-slug',
          path: `/post/${post.categories[0]}/${post.slug}`,
          params: {
            sport: post.categories[0],
            slug: post.slug,
            post: JSON.stringify(post),
          },
        }
      }

      return {
        name: 'post-detail-slug',
        path: `/post/${post.slug}`,
        params: {
          slug: post.slug,
          post: JSON.stringify(post),
        },
      }
    }

    return {
      ...toRefs(event),
      handleClick,
      getColor,
      getSportIcon,
      getMoreDict,
      fontSizeTitle,
      fontSizeDesc,
    }
  },
}

</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/_variables';
.post-items {
  font-weight: 700;
  padding: 0.5rem 0.75rem;
  border: 0px;
  color: $primary;
}
</style>
