<template>
  <div
    class="swiper-multiple post-mini-composite"
    style="width: 100%; justify-content: center; min-height: 250px;"
  >
    <div
      class="kf_hd2"
      style="float: none;"
    >
      <span>
        <b-img
          v-if="image"
          v-b-modal.modal-scrollable
          :src="require(`@/assets/images/icons/sports/${image}.svg`)"
          height="32px"
          style="padding-right: 10px; padding-bottom: 5px;"
        />
        <strong class="titleClass">{{ title }}</strong>
        <i
          v-for="(team, index) in favoriteTeams"
          :key="`${uuid}-${index}`"
          class="item"
        >
          <b-img
            rounded="rounded"
            :src="getTeamImage(team.category, team.name)"
            blank-color="#ccc"
            :alt="team.name"
            style="margin: 5px; height: 30px; border: 1px solid gray; padding: 3px; background: white;"
            :style="team.name === filterTeam ? 'border: 3px solid orange;' : '' "
            @click="selectTeam(team.category, team.name, team.fullname)"
          />
        </i>
      </span>
    </div>

    <swiper
      :options="swiperOptions"
      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
      style="max-height: 250px;"
    >
      <!-- Slides -->
      <swiper-slide v-if="filterTeam === null && filteredPosts.length === 0">
        <div style="background: white; min-height: 210px;">
          <a
            v-b-modal.modal-scrollable
            class="more-teams-link"
          >
            Select <span style="color: #fcb737;">Favorites</span>
            <br>
            <feather-icon
              icon="HeartIcon"
              size="50"
              badge="+"
              style="color: white;"
              fill="#352a81"
              badge-classes="badge-secondary badge-small"
            />
          </a>

        </div>
      </swiper-slide>

      <swiper-slide
        v-for="(post, index) in filteredPosts.slice(0, 20)"
        :key="`${uuid}-${index}-Y`"
      >
        <post-mini
          :post="post"
          :tmp="index"
          :minimal="false"
          :collapsed="true"
          style="min-height: 210px;"
        />
      </swiper-slide>

      <swiper-slide v-if="filterTeam !== null">
        <div style="background: white; min-height: 210px;">
          <router-link
            class="more-teams-link"
            :to="`/${filterSport}/${filterTeam}`"
          >
            More <span style="color: #fcb737;">{{ filterTeamName }}</span> Posts
            <br>
            <feather-icon
              icon="ArrowRightCircleIcon"
              size="50"
            />
          </router-link>
        </div>
      </swiper-slide>

      <!-- If we need pagination -->
      <div
        slot="pagination"
        class="swiper-pagination"
        style="position: unset; margin-bottom: 5px;"
      />
    </swiper>
  </div>
</template>

<script>
import {
  ref, computed,
} from '@vue/composition-api'

import {
  BImg,
} from 'bootstrap-vue'

// import style
import 'swiper/swiper-bundle.min.css'

import store from '@/store'
import prosports from '@/config/sports/pro/prosports'
import { v4 as uuidv4 } from 'uuid'

import PostMini from './PostMini.vue'

export default {
  components: {
    PostMini,
    BImg,
  },

  props: {
    posts: {
      type: Array,
      default: () => [],
      // validator: (prop) => prop.every(e => typeof e === 'string'),
    },
    title: {
      type: String,
      default: '',
    },
    image: {
      type: String,
      default: '',
    },
  },

  setup(props) {
    const uuid = ref(uuidv4())
    const filterTeam = ref(null)
    const filterTeamName = ref(null)
    const filterSport = ref(null)

    const favoriteTeams = computed(() => {
      const teams = []
      const storeTeams = store.getters['appData/getFavoriteTeamsList']
      if (storeTeams.length > 0) {
        storeTeams.forEach(team => {
          teams.push({
            title: team.title,
            route: team.route,
            icon: 'UserIcon',
            resource: 'Public',
            action: 'read',
            category: team.sport,
            name: team.path,
            fullname: team.name,
          })
        })
      }
      return teams
    })

    // const favoriteTeamsOLD = computed(() => {
    //   const localteams = []
    //   // const ft = store.state.appConfig.layout.favoriteTeams
    //   const ft = store.state.appData.favoriteTeams
    //   if (ft.length > 0) {
    //     const favorites = ft.split(';')
    //
    //     if (favorites.length > 0) {
    //       favorites.forEach(s => {
    //         const i = s.indexOf(':')
    //         const splits = [s.slice(0, i), s.slice(i + 1)]
    //
    //         localteams.push({
    //           title: `${splits[0]} / ${splits[1]}`,
    //           route: `team-${splits[0]}-${splits[1]}`,
    //           icon: 'UserIcon',
    //           resource: 'Public',
    //           action: 'read',
    //           category: splits[0],
    //           name: splits[1],
    //         })
    //       })
    //     }
    //   }
    //   return localteams
    // })

    const swiperOptions = {
      freeMode: true,
      slidesPerView: 4,
      spaceBetween: 5,
      // Responsive breakpoints
      breakpoints: {
        // when window width is >= 0px
        0: {
          slidesPerView: 2,
          spaceBetween: 5,
        },
        480: {
          slidesPerView: 2,
          spaceBetween: 5,
        },
        640: {
          slidesPerView: 4,
          spaceBetween: 5,
        },
        960: {
          slidesPerView: 5,
          spaceBetween: 5,
        },
        1280: {
          slidesPerView: 6,
          spaceBetween: 5,
        },
        1500: {
          slidesPerView: 7,
          spaceBetween: 5,
        },
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
    }

    function getTeamImage(sport, name) {
      if (sport === undefined || (!(sport in prosports) && (sport !== 'sports'))) { // && !(category in soccer))) {
        return ''
      }

      let imagePath = ''
      if (sport === 'sports' && name in prosports) {
        imagePath = prosports[name].logo
      } else if (sport in prosports) {
        const { divisions } = prosports[sport]
        Object.keys(divisions).forEach(division => {
          const teamsInDivision = divisions[division].teams
          const x = teamsInDivision.filter(t => t.name.toLowerCase().replace(/\s/g, '') === name.replace(/\s/g, ''))
          if (x.length > 0) {
            imagePath = x[0].logo
          }
        })
      }

      // if (sport in soccer) {
      //   const { divisions } = soccer[sport]
      //   Object.keys(divisions).forEach(division => {
      //     const teamsInDivision = divisions[division].teams
      //     const x = teamsInDivision.filter(t => t.name.toLowerCase() === name)
      //     if (x.length > 0) {
      //       imagePath = x[0].image
      //     }
      //   })
      // }

      return imagePath
    }

    const filteredPosts = computed(() => {
      if (filterTeam.value === null) {
        return props.posts
      }
      const items = props.posts
      const results = []
      Object.keys(items).forEach(key => {
        if (key !== undefined) {
          const post = items[key]
          // if (post.categories.some(categories => categories === filterTeam.value)) {
          if (filterSport.value === 'sports') {
            if (post.categories[0] === filterTeam.value) {
              results.push(post)
            }
          } else if (post.team === filterTeam.value) {
            results.push(post)
          }
        }
      })
      return results
    })

    function selectTeam(sport, team, name) {
      filterSport.value = sport
      filterTeam.value = team
      filterTeamName.value = name
    }

    return {
      uuid,
      swiperOptions,
      favoriteTeams,
      getTeamImage,
      selectTeam,
      filterTeam,
      filterTeamName,
      filterSport,
      filteredPosts,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/_variables';
.post-mini-composite {
  background-color: rgb(233, 233, 233);
  padding: 5px 5px 5px 5px;
}

.more-teams-link {
  width: 100%;
  color: #352a81;
  font-size: 24px;
  text-transform: uppercase;
  font-weight: 800;
  text-align: center;
  margin: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
</style>
