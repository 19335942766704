<template>
  <div class="newsletter-subscribe">
    <div>
      <div class="title">
        <span
          class="topic-logo"
          style="border-color: #073759;"
        >
          <img
            :src="require('@/assets/images/logo/dek_sports_01.png')"
            alt="logo"
          >
        </span>
        <h5 class="title-text">
          Newsletter
        </h5>
      </div>
      <div class="subtitle">
        DekSports.com Daily News!
      </div>
    </div>

    <form
      class="form-inline"
      method="post"
    >
      <div
        class="form-group"
        style="margin-bottom: 0px;"
      >
        <input
          class="form-control"
          type="email"
          name="email"
          placeholder="Your Email"
        >
      </div>
      <div
        class="form-group"
        style="margin-bottom: 0px;"
      >
        <button
          class="btn btn-primary"
          type="submit"
        >
          Subscribe
        </button>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  components: {},

  setup() {
    return {}
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/_variables';

.title {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0;
  margin-left: 0px;
  margin-right: 0px;
  clear: both;
  text-align: center;
}

.subtitle {
  text-align: center;
  padding: 8px;
  font-size: 16px;
  font-weight: 800;
  color: $secondary;
  box-sizing: border-box;
}

.topic-logo {
  padding-right: 15px;
  float: none;
  width: unset;
  height: unset;
  padding-left: unset;
  vertical-align: unset;
  border-right: 1px solid;
}
.topic-logo img {
  height: 65px;
  width: auto;
  vertical-align: middle;
  border: 0;
}
.title-text {
  color: $primary;
  margin-left: 10px;
  margin-top: 12.5px;
  font-size: 22px;
  text-transform: uppercase;
  padding-left: 0px;
  padding-right: 0px;
  display: inline-block;
  float: none;
  font-family: Oswald,Arial,sans-serif;
  font-weight: 700;
  letter-spacing: -.0015em;
  text-align: left;
}

.newsletter-subscribe {
  background-color: #fff;
  padding: 10px 0 10px 0;
}

.newsletter-subscribe form {
  justify-content:center;
}

.newsletter-subscribe form .form-control {
  background:#eff1f4;
  border:none;
  border-radius:3px;
  box-shadow:none;
  outline:none;
  color:inherit;
  text-indent:9px;
  height:35px;
  margin-right: 5px;
  max-width:200px;
  font-size: 0.9rem;
  padding-left: 5px;
}

.newsletter-subscribe form .btn {
  padding:12px 12px;
  border:none;
  background:none;
  box-shadow:none;
  text-shadow:none;
  opacity:0.9;
  text-transform:uppercase;
  font-weight:bold;
  font-size:11px;
  letter-spacing:0.4px;
  line-height:1;
}

.newsletter-subscribe form .btn:hover {
  opacity:1;
}

.newsletter-subscribe form .btn:active {
  transform:translateY(1px);
}

.newsletter-subscribe form .btn-primary {
  background-color: $secondary !important;
  color:#fff;
  outline:none !important;
}
</style>
