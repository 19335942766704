/* eslint-disable global-require */
import proconfig from '@/config/sports/pro/index'
// import collegeconfig from '@/config/sports/college/index'
// import otherconfig from '@/config/sports/other/index'

import prosports from '../../config/sports/pro/prosports'
// import college from '../../config/sports/college/college'
// import other from '../../config/sports/other/other'
// import soccer from '../../config/sports/soccer/soccer'

import createNavRoutes from '../createNavRoutes'

// const soccerRoutes = createSportRoutes(soccer)

const defaultNavRoutes = []

// Add the Sports Header.
defaultNavRoutes.push(
  {
    header: 'Pro Sports',
    resource: 'Public',
    action: 'read',
  },
)

// Add the individual sport routes to the list of default routes.
const proNavRoutes = createNavRoutes(prosports, proconfig)
proNavRoutes.forEach(route => {
  defaultNavRoutes.push(route)
})

defaultNavRoutes.push(
  {
    title: 'Sports',
    route: 'sports',
    resource: 'Public',
    action: 'read',
    icon: require('@/assets/images/icons/sports/sports.png'),
    iconSource: 'image',
  },
)

defaultNavRoutes.push(
  {
    title: 'Headline',
    route: 'headline',
    resource: 'Public',
    action: 'read',
    icon: require('@/assets/images/icons/sports/sports.png'),
    iconSource: 'image',
  },
)

// // Add the Sports Header.
// defaultNavRoutes.push(
//   {
//     header: 'Other Sports',
//     resource: 'Public',
//     action: 'read',
//   },
// )

// const otherNavRoutes = createNavRoutes(other, otherconfig)
// otherNavRoutes.forEach(route => {
//   defaultNavRoutes.push(route)
// })

export default defaultNavRoutes
