<template>
  <b-card
    no-body
    class="overflow-hidden youtube rounded-0"
    style="margin-bottom: 0px; max-height: 600px;"
  >

    <template v-if="$can('update', 'Post')">
      <router-link
        v-if="$can('update', post)"
        class="nav-link"
        :to="`/authors/post/edit/${post.id}`"
        style="margin: 0px; padding: 0px;"
      >
        <a class="mc-btn-action">
          <i class="fa fa-pencil" />
        </a>
      </router-link>
      <a
        v-else
        class="mc-btn-action"
      >
        <i class="fa fa-lock" />
      </a>
    </template>

    <b-row
      no-gutters
      class="post-row"
    >

      <b-col
        lg="12"
        md="12"
        sm="12"
        cols="12"
      >

        <b-card-body style="padding: 10px 15px 15px 15px;">

          <h5 class="category-social">
            <i class="fa fa-newspaper-o" /><i class="fa2">News</i>
          </h5>

          <b-card-text
            v-b-toggle="'collapse-' + post.slug"
            class="post-title page-link-local"
          >
            {{ post.title }} [{{ post.clicks }}]
          </b-card-text>

          <b-collapse
            :id="`collapse-${post.slug}`"
            :visible="$store.getters['app/currentBreakPoint'] !== 'xs' && $store.getters['app/currentBreakPoint'] !== 'sm' && $store.getters['app/currentBreakPoint'] !== 'md'"
            class="mt-1"
            style="padding: 0 0 10px 0;"
          >
            <b-card-text class="post-description">
              {{ post.description }}
            </b-card-text>
          </b-collapse>

          <b-card-text class="post-time small text-muted">
            {{ formatDate(new Date(post.pubDate)) }}
          </b-card-text>
        </b-card-body>
      </b-col>

    </b-row>
  </b-card>
</template>

<script>
import {
  reactive, toRefs,
} from '@vue/composition-api'

import {
  BCard,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BCollapse,
  VBToggle,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'

import moment from 'moment'

export default {
  components: {
    // Card components
    BCard,
    BCardText,
    BCardBody,
    BRow,
    BCol,
    BCollapse,
  },

  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },

  props: {
    post: {
      type: Object,
      default: null,
    },
  },

  setup(props, context) {
    const event = reactive({
      getBaseUrl: context.root.$baseFrontendUrl,
    })

    const formatDate = dt => {
      if (dt) {
        // return '17 minutes ago'
        // return moment(dt, 'HH:MM:SS').format('MM/DD/YYYY hh:mm')
        const a = moment(dt)
        const b = moment(new Date())
        const minutes = b.diff(a, 'minutes')
        if (minutes > 60) {
          if (minutes > 24 * 60) {
            if (minutes > 3 * 24 * 60) {
              return a.format('MM/DD/YYYY hh:mm')
            }
            const val = Math.round(minutes / (24 * 60))
            return `${val} days ago`
          }
          const val = Math.round(minutes / 60)
          return `${val} hours ago`
        }

        return `${minutes} minutes ago`
      }
      return ''
    }

    const handleClick = e => {
      let url = e.target.href

      if (url.endsWith('/')) {
        url = url.slice(0, url.length - 1)
      }

      if (e.button === 0) {
        window.open(url, '_blank')
      } else if (e.button === 1) {
        // Skip middle click, handled by browser.
      } else if (e.button === 2) {
        window.open(url, '_blank')
      }

      e.preventDefault()
    }

    return {
      ...toRefs(event),
      formatDate,
      handleClick,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/_variables';
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css");

$border: #ebebeb;
$text: $primary;
$catpad: 15px;

@mixin transition($args...) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}

.page-link-local, .page-link-local:visited, .page-link-local:hover, .page-link-local:active, .page-link-local:focus {
  outline: none !important;
}

.image-tmp {
  height: 100%;
}

.post-category {
  display: inline-block;
  text-transform: uppercase;
  font-size: 0.9rem;
  font-weight: 700;
  line-height: 1;
  letter-spacing: 0.0625rem;
  margin: $catpad 0.0rem 0.0rem $catpad;
  border-bottom: 0.2rem solid $border;

  @media screen and (max-width: 992px) {
    font-size: 0.7em;
    margin: 10px 0 0 $catpad;
  }
}

.post-title {
  @include transition(color 0.3s ease);
  font-size: 1.1rem;
  line-height: 1.15;
  font-weight: 500;
  overflow: hidden;
  color: $text;

  @media screen {
    height: 100%;
  }

  @media screen and (max-width: 992px) {
    font-size: 0.9em;
  }
}

.post-description {
  @include transition(color 0.3s ease);
  font-size: 0.9rem;
  line-height: 1.15;
  color: $text;
  font-weight: 400;
  width: 90wv;
  padding: 0 0 10px 0;
}

.post-time {
  color: $text;
  line-height: 1;
  z-index: 10;
  text-align: right;
  // position: sticky;
  // padding: 10px 0 0 0;
  position: absolute;
  bottom: 10px;
  right: 10px;
 }

.post-row {
  // Fixes the compressed view where the images don't fit the
  // height of the card. If applied to all sizes, even when image
  // is above the text, then the image sizes will be different for
  // different cards.
  @media screen and (max-width: 992px) {
    min-height: 100%;
  }
}

.youtube {
  // background: linear-gradient(60deg, #10a324, #0caa53);
  background: linear-gradient(90deg, #00ff00 10px, #ffffff 0%)
}

.category-social .fa {
  font-size: 24px;
  position: relative;
  margin-top: -4px;
  top: 2px;
  margin-right: 5px;
  color: $text;
}

.fa2 {
  font-size: 18px;
  position: relative;
  margin-left: 3px;
  color: $text;
}

.mc-btn-action {
  position: absolute;
  right: 8px;
  top: 8px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  border: 2px solid;
  width: 36px;
  height: 36px;
  line-height: 33px;
  text-align: center;
  background-color: $primary;
  color: $secondary;
  cursor: pointer;
  z-index: 20;
  //.mc-transtition();
}

</style>
