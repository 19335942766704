<template>
  <section
    class="ad"
    :style="`height: ${height}`"
  >
    <div class="container">
      <a href="http://www.test.com/">
        <div
          class="logo"
          alt="DekSports"
        />
      </a>
      <a href="http://www.testcom/sale.html">
        <h2>Get Deal</h2>
      </a>
    </div>
  </section>
</template>

<script>
export default {
  components: {},

  props: {
    height: {
      type: String,
      default: '106px',
    },
  },

  setup() {
    return {}
  },
}

</script>

<style lang="scss" scoped>
// Variables
$background: url('~@/config/images/ads/nfladimage.png');
$logo: url('~@/assets/images/logo/logo.png');
$white: #ffffff;
$ghost: rgba(255,255,255,.9);
$hover: rgba(245,160,25,.8);

// Actual Banner Code
.ad {
  margin: 0px auto 5px auto;
  max-width: 100%;
  background-image: $background;
  background-size: cover;
  background-position: left center;
  border: 1px solid #333;

  @media screen and (max-width: 1100px) {
    max-width: 1000px;
  }

  @media screen and (max-width: 976px) {
    max-width: 800px;
  }

  @media screen and (max-width: 768px) {
    max-width: 600px;
  }

  @media screen and (max-width: 576px) {
    max-width: 350px;
  }

  @media screen and (max-width: 400px) {
    max-width: 320px;
  }

  .container {
    clear: both;
    padding: 1px 0 0 0;
    .logo {
      width: 50px;
      height: 50px;
      margin: auto;
      background-image: $logo;
      background-size: cover;
      background-position: center center;
    }
    a {
      display: block;
      color: $white;
      text-decoration: none;
    }
  }
  h2 {
    background-color: $hover;
    padding: 10px;
    font-weight: 600;
    font-size: 1.5em;
    vertical-align:middle;
    font-family: 'Oswald', sans-serif;
    text-transform: uppercase;
    transition: all .15s ease;
    text-align: center;
    &:hover {
      background-color: $hover;
      transition: all .15s ease;
    }
  }
}

@media screen and (min-width: 525px) {
  .ad {
    height: 106px;
    .container {
      padding: 10px;
    }
    .logo {
      float: left;
    }
    h2 {
      float: right;
      background-color: $ghost;
    }
  }
}

</style>
