<template>
  <div>
    <div
      class="container"
      style="display: inline-flex; flex-flow: column wrap; padding: 0px;"
      :style="enableHorizontalSportMenu ? '' : 'margin-top: -1.5rem;'"
    >

      <ad-banner
        v-show="$store.getters['app/isBiggerThanBreakPoint']('xl')"
        style="margin-bottom: 10px;"
      />

      <!-- <Scores
        v-for="(key) in Object.keys(games)" :key="key"
        style="padding-bottom: 20px;"
        :games="games[key]"
      /> -->

      <Scores
        style="padding-bottom: 0px;"
        :games="games"
      />

      <b-collapse
        id="collapse-banner"
        visible
        style="margin-bottom: 10px;"
      >
        <b-card-text>
          <img
            class="banner"
            :src="require('@/assets/images/logo/dek_banner2.png')"
          >
        </b-card-text>
      </b-collapse>

      <PostFavoriteComposite
        :posts="favorites"
        title="My News:"
        style="margin-bottom: 5px;"
        divider-path="headlines"
        image="heart"
      />

      <!-- [{{ sport }}] [{{ team }}] [{{ slug }}] -->
      <b-row
        no-gutters
        class="column d-md-block"
        style="margin: 0px; padding: 0px;"
      >
        <b-col
          class="col-sm-12 col-md-12 col-lg-12 col-xl-9 col-12 float-md-left"
          style="margin: 0px; padding: 5px;"
        >
          <!--
            *********************************************************
            * A
            *********************************************************
          -->

          <post-composite
            name="TopHeadlines"
            :posts="cache.top[1].posts.slice(0, 15)"
            style="margin-right: 0; margin-left: 0;"
            divider="Top Headlines"
            divider-path="headlines"
            image="star"
          />

          <ad-banner style="height: 100px; margin-top: 20px; margin-bottom: 20px;" />

        </b-col>

        <b-col
          class="col-sm-12 col-md-12 col-lg-12 col-xl-3 col-12 float-md-right"
          style="margin: 0px; padding: 5px;"
        >
          <!--
            *********************************************************
            * B
            *********************************************************
          -->
          <div>
            <!-- <GameStatus
              sport="NFL"
              home-team="Bears"
              away-team="Packers"
              home-score="72"
              away-score="0"
              home-logo="https://a.espncdn.com/combiner/i?img=/i/teamlogos/nfl/500/chi.png&scale=crop&cquality=40&location=origin&w=80&h=80"
              away-logo="https://a.espncdn.com/combiner/i?img=/i/teamlogos/nfl/500/gb.png&scale=crop&cquality=40&location=origin&w=80&h=80"
              home-record="15-0"
              away-record="0-15"
              game-date="Nov 30th"
              game-time="13:13 in 4th"
            /> -->

            <TopList
              :posts="cache.top[1].posts.slice(15, 25)"
              title-first="More"
              title-second="Headlines"
              :collapsible="false"
              :display-sports="true"
              style="margin-bottom: 20px;"
            />

            <Newsletter style="margin-bottom: 20px;" />

            <vue-word-cloud
              style="height: 150px; margin-bottom: 20px;"
              :words="defaultWordsCloud"
              :color="([, weight]) => weight > 20 ? '#352a81' : weight > 1 ? '#fcb737' : 'Indigo'"
              font-family="Roboto"
              :font-size-ratio="3"
              :spacing="0.2"
            >
              <template slot-scope="{text, weight}">
                <div
                  :title="weight"
                  style="cursor: pointer;"
                  @click="onWordClick(text)"
                >
                  {{ text }}
                </div>
              </template>
            </vue-word-cloud>

            <ad-banner style="height: 200px; margin-bottom: 20px;" />

            <post-composite
              name="sideposts"
              :posts="cache.top[1].posts.slice(25, 30)"
              style="margin-right: 0; margin-left: 0; margin-bottom: 20px;"
              :minimal="true"
              :carousel-indexes="[2]"
            />

            <ad-banner style="height: 200px; margin-bottom: 20px;" />

          </div>
        </b-col>
      </b-row>

      <b-row
        no-gutters
        class="column d-md-block"
        style="margin: 0px; padding: 0px;"
      >
        <b-col
          class="col-sm-12 col-md-12 col-lg-12 col-xl-9 col-12 float-md-left"
          style="margin: 0px; padding: 5px;"
        >
          <!--
            *********************************************************
            * D
            *********************************************************
          -->

          <!-- <post-composite
            name="favorites"
            :posts="favorites.slice(0, 35)"
            style="margin-right: 0; margin-left: 0;"
            divider="My Favorites"
            divider-path="favorites"
            image="heart"
          />

          <ad-banner /> -->

          <post-composite
            name="ExtraHeadlines"
            :posts="cache.top[1].posts.slice(30, 40)"
            style="margin-right: 0; margin-left: 0;"
          />

        </b-col>

        <b-col
          class="col-sm-12 col-md-12 col-lg-12 col-xl-3 col-12 float-md-right"
          style="margin: 0px; padding: 5px;"
        >
          <!--
            *********************************************************
            * C
            *********************************************************
          -->
          <div>

            <Socials style="margin-bottom: 20px;" />

            <ad-banner style="height: 200px; margin-bottom: 20px;" />

            <people-suggestions
              style="margin-bottom: 20px;"
              :people="data.people.filter(person => person.sport === 'nba').slice(0, 5)"
            />

            <ad-banner style="height: 200px; margin-bottom: 20px;" />

            <TopList
              :posts="cache.top[1].posts.slice(40, 50)"
              title-first="More"
              title-second="Headlines"
              :collapsible="false"
              :display-sports="true"
              style="margin-bottom: 20px;"
            />

            <!-- <people-suggestions
              :people="data.people.filter(person => person.sport === 'nhl').slice(0, 5)"
            />

            <post-composite
              name="favoritessmall"
              :posts="favorites.slice(20, 24)"
              style="margin-right: 0; margin-left: 0;"
              :minimal="true"
            />

            <ad-banner height="200px" />

            <DailyDek
              name="DailyDek1"
              :posts="favorites.slice(0, 3)"
              style="margin-bottom: 20px;"
            />

            <ad-banner height="200px" />

            <people-suggestions
              :people="data.people.filter(person => person.sport === 'nba').slice(0, 5)"
            />

            <TwitterFeed
              :posts="data.tweets"
              style="margin-bottom: 20px;"
            />

            <VideoFeed style="margin-bottom: 20px;" /> -->

          </div>
        </b-col>
      </b-row>

      <PostSportComposite
        :posts="cache.top[1].posts.slice(0, 50)"
        title="Sports:"
        style="margin-bottom: 20px;"
      />

      <ad-banner />
    </div>

    <ScrollToTop />
  </div>
</template>

<script>
import {
  reactive, computed, ref,
} from '@vue/composition-api'

import { v4 as uuidv4 } from 'uuid'
import VueWordCloud from 'vuewordcloud'

// import { subject as an } from '@casl/ability'

import store from '@/store'
// import router from '@/router'

import { $themeConfig } from '@themeConfig'

import {
  BRow,
  BCol,
  BCardText,
  BCollapse,
  VBToggle,
} from 'bootstrap-vue'

import ScrollToTop from '@core/components/scroll-to-top/ScrollToTop.vue'
import PeopleSuggestions from './PeopleSuggestions.vue'
import PostFavoriteComposite from '../posts/PostFavoriteComposite.vue'
import PostSportComposite from '../posts/PostSportComposite.vue'
import AdBanner from '../posts/AdBanner.vue'
import TopList from '../posts/TopList.vue'
import PostComposite from '../posts/PostComposite.vue'
// import DailyDek from '../posts/DailyDek.vue'
import Newsletter from '../pages/Newsletter.vue'
import Socials from '../pages/Socials.vue'
// import GameStatus from './GameStatus.vue'
import Scores from './Scores.vue'

// import TwitterFeed from '../pages/TwitterFeed.vue'
// import VideoFeed from '../pages/VideoFeed.vue'

export default {
  name: 'DekSports',

  metaInfo: {
    title: 'Dek Sports',
    titleTemplate: '%s | Nothing But Sports',
    htmlAttrs: {
      lang: 'en-US',
    },
    meta: [
      { charset: 'utf-8' },
      { name: 'description', content: 'Sports news, rumors, headlines, and articles from around the web. Follow your favorite teams and topics.' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { name: 'keywords', content: 'DekSports,sports,headlines,posts,articles,news,rumors,search,nfl,nhl,mlb,nba,soccer,college"' },
    ],
  },

  components: {
    BRow,
    BCol,
    BCardText,
    BCollapse,

    ScrollToTop,
    PeopleSuggestions,
    VueWordCloud,
    PostFavoriteComposite,
    PostSportComposite,
    AdBanner,
    PostComposite,

    TopList,
    // DailyDek,
    Newsletter,
    Socials,
    // TwitterFeed,
    // VideoFeed,
    // GameStatus,
    Scores,
  },

  directives: {
    'b-toggle': VBToggle,
  },

  // setup(props, context) {
  setup(props, context) {
    const uuid = ref(uuidv4())

    const userId = computed(() => {
      const userData = JSON.parse(localStorage.getItem('userData'))
      if (userData) {
        // event.doesUserLike = props.post.likes.includes(userData._id)

        /* eslint no-underscore-dangle: ["error", { "allow": ["foo_", "_id"] }] */
        return userData._id
      }
      return 'n/a'
    })

    const {
      enableHorizontalSportMenu,
    } = $themeConfig.layout

    const data = reactive({
      posts: [],
      people: [],
      tweets: [],
      pages: 1,
      currentPage: null,
    })

    data.people = computed(() => store.getters['appData/getPeople'])

    // data.posts = computed(() => {
    //   const results = store.getters['appData/getPostsForCurrentPageXXS']
    //   if (results.count > 0) {
    //     data.pages = Math.ceil(results.count / 50.0)
    //   } else {
    //     data.pages = 1
    //   }
    //   return results
    // })

    // async function fetchTopPosts(page) {
    //   store.dispatch('appData/fetchTop', { page })
    // }

    async function fetchPosts(sport, page, status) {
      // store.dispatch('appData/fetchPostsForCategoryPage', { category, page })
      store.dispatch('appData/fetchPosts', { sport, page, status })
    }
    async function fetchFavoritePosts() {
      // store.dispatch('appData/fetchPostsForCategoryPage', { category, page })
      store.dispatch('appData/fetchFavoritePosts')
    }
    async function fetchWordCloud() {
      store.dispatch('appData/fetchWordCloud')
    }

    // const page = computed(() => parseInt(context.root.$route.query.page, 10) || 1)
    // fetchTopPosts(page)

    // Request FAVORITE posts second (unless user selects favorites as listed first)
    fetchFavoritePosts()

    // Request HEADLINE posts first
    fetchPosts('top', 1, 'headline')

    fetchWordCloud()

    // fetchPosts('favorites', 1)

    const cache = computed(() => store.getters['appData/getCache'])
    const favorites = computed(() => store.getters['appData/getFavorites'])
    // const favoriteTeams = computed(() => store.getters['appConfig/getFavoriteTeams'])
    const defaultWordsCloud = computed(() => store.getters['appData/getWordCloud'])

    // watch(favorites, newfavorites => {
    //   console.log('----------------NEW FAVORITES---------')
    //   console.log(newfavorites)
    // }, {
    //   immediate: true,
    // })

    function onWordClick(word) {
      this.$router.push({ name: 'search', query: { keywords: word } })
    }

    function toast(toaster, append = false) {
      this.counter += 1
      this.$bvToast.toast(`Toast ${this.counter} body content`, {
        title: `Toaster ${toaster}`,
        toaster,
        solid: true,
        appendToast: append,
        timeout: 2000,
      })
    }

    // context.root.$on('bv::collapse::state', (collapseId, isJustShown) => {
    //   if (collapseId === 'collapse-banner') {
    //     console.log('collapseId:', collapseId)
    //     console.log('isJustShown:', isJustShown)
    //     if (isJustShown) {
    //       setTimeout(() => {
    //         console.log('collapse-------------------->')
    //         context.root.$emit('bv::toggle::collapse', 'collapse-banner')
    //       }, 5000)
    //     }
    //   }
    // })

    const sportsNames = ['nfl', 'nhl', 'nba', 'mlb']
    async function fetchGames(sportName) {
      store.dispatch('appData/fetchGames', { sport: sportName })
    }
    for (let i = 0; i < sportsNames.length; i += 1) {
      fetchGames(sportsNames[i])
    }

    const games = computed(() => {
      // const gamesdata = {}
      // for (let i = 0; i < sportsNames.length; i += 1) {
      //   gamesdata[sportsNames[i]] = (store.getters['appData/getGames'](sportsNames[i]))
      // }
      // return gamesdata

      let gamesdata = []
      for (let i = 0; i < sportsNames.length; i += 1) {
        const sportGames = store.getters['appData/getGames'](sportsNames[i])
        if (sportGames.length > 0) {
          gamesdata = [...gamesdata, [sportsNames[i]], ...sportGames]
        }
      }
      return gamesdata
    })

    setTimeout(() => {
      context.root.$emit('bv::toggle::collapse', 'collapse-banner')
    }, 5000)

    return {
      data,
      cache,
      favorites,
      userId,
      uuid,
      defaultWordsCloud,
      onWordClick,
      enableHorizontalSportMenu,
      toast,
      games,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/_variables';
@import '@core/scss/vue/pages/page-blog.scss';

$border: #ebebeb;
$text: #444;
$catpad: 15px;

@mixin transition($args...) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}

body { font-family: sans-serif; }
h3 { text-align: center; }

.a, .b, .c, .d {
  min-height:100px;
}

.tall {
  min-height:200px;
}

.double {
  min-height:250px;
}

.super {
  min-height:650px;
}

.page-link-local, .page-link-local:visited, .page-link-local:hover, .page-link-local:active, .page-link-local:focus {
  outline: none !important;
}

.image-tmp {
  height: 100%;
  max-height: 200px;

  // Sets the maximum height to higher value when the card changes
  // from picture on top to picture on left side.
  @media screen and (max-width: 992px) {
    max-height: 600px;
  }

  overflow: hidden;
  transition: transform 0.5s ease;
  transition-property: transform;
  transition-duration: 0.5s;
  transition-timing-function: ease;
  transition-delay: 0s;
  backface-visibility: hidden;
  object-fit: cover;
}

.image-tmp:hover {
  transform: scale(1.1);
}

a {
  text-decoration: none;
  color: #3498db;
}

.dropdown-menu #zzz {
  max-width: 2rem !important;
  white-space: normal;
  min-width:inherit;
}

.wrap-dropdown {
  max-width: 2rem !important;
  white-space: normal;
  min-width:inherit;
}
.dropdown-item {
  max-width: 2rem !important;
  white-space: normal;
  min-width:inherit;
}

.center {
  display: flex;
  justify-content: center;
}

.ad {
  width: 100%;
  // height: auto;
}
// .ad {
//   width: 100vw;
//   height: 125px;
//   display: block;
//   margin-left: auto;
//   margin-right: auto;
//   padding: 20px;
// }

.banner {
  width: 100%;
  max-height: 200px;
  position: relative;
  margin-bottom: 10px;

  @media screen and (max-width: 568px) {
    max-height: 120px;
  }
}

</style>
