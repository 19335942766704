<template>
  <div
    class="swiper-multiple post-sport-composite"
    style="width: 100%; justify-content: center; min-height: 250px;"
  >
    <div
      class="kf_hd2"
      style="float: none;"
    >
      <span>
        <strong class="titleClass">{{ title }}</strong>
        <i
          v-for="(item, index) in sportMenuItems"
          :key="`${uuid}-${index}-X`"
        >
          <b-img
            v-if="item.iconSource !== undefined"
            rounded="rounded"
            :src="item.icon"
            blank-color="#ccc"
            :alt="item.route"
            style="margin: 5px; height: 30px; border: 1px solid gray; padding: 3px; background: white;"
            :style="item.title.toLowerCase() === filterSport ? 'border: 3px solid orange;' : '' "
            @click="selectSport(item.title)"
          />
        </i>
      </span>
    </div>

    <swiper
      :options="swiperOptions"
      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
      style="max-height: 250px;"
    >
      <!-- Slides -->
      <swiper-slide
        v-for="(post, index) in filteredPosts.slice(0, 20)"
        :key="`${uuid}-${index}-Y`"
      >
        <post-mini
          :post="post"
          :tmp="index"
          :minimal="false"
          :collapsed="true"
          style="min-height: 230px;"
        />
      </swiper-slide>

      <swiper-slide v-if="filterSport !== null">
        <div style="background: white; min-height: 230px;">
          <router-link
            class="more-sports-link"
            :to="`/${filterSport}`"
          >
            More <span style="color: #fcb737;">{{ filterSport }}</span> Posts
            <br>
            <feather-icon
              icon="ArrowRightCircleIcon"
              size="50"
            />
          </router-link>
        </div>
      </swiper-slide>
      <!-- If we need pagination -->
      <div
        slot="pagination"
        class="swiper-pagination"
        style="position: unset; margin-bottom: 5px;"
      />
    </swiper>
  </div>
</template>

<script>
import {
  ref, computed,
} from '@vue/composition-api'

import {
  BImg,
} from 'bootstrap-vue'

// import style
import 'swiper/swiper-bundle.min.css'

import sportMenuItems from '@/navigation/vertical/sports'

import store from '@/store'
import prosports from '@/config/sports/pro/prosports'
import { v4 as uuidv4 } from 'uuid'

import PostMini from './PostMini.vue'

export default {
  components: {
    PostMini,
    BImg,
  },

  props: {
    posts: {
      type: Array,
      default: () => [],
      // validator: (prop) => prop.every(e => typeof e === 'string'),
    },
    title: {
      type: String,
      default: '',
    },
  },

  setup(props) {
    const uuid = ref(uuidv4())
    const filterSport = ref(null)

    const favoriteTeams = computed(() => {
      const localteams = []
      // const ft = store.state.appConfig.layout.favoriteTeams
      const ft = store.state.appData.favoriteTeams
      if (ft.length > 0) {
        const favorites = ft.split(';')

        if (favorites.length > 0) {
          favorites.forEach(s => {
            const i = s.indexOf(':')
            const splits = [s.slice(0, i), s.slice(i + 1)]

            localteams.push({
              title: `${splits[0]} / ${splits[1]}`,
              route: `team-${splits[0]}-${splits[1]}`,
              icon: 'UserIcon',
              resource: 'Public',
              action: 'read',
              category: splits[0],
              name: splits[1],
            })
          })
        }
      }
      return localteams
    })

    const swiperOptions = {
      freeMode: true,
      slidesPerView: 4,
      spaceBetween: 5,
      // Responsive breakpoints
      breakpoints: {
        // when window width is >= 0px
        0: {
          slidesPerView: 2,
          spaceBetween: 5,
        },
        480: {
          slidesPerView: 2,
          spaceBetween: 5,
        },
        640: {
          slidesPerView: 4,
          spaceBetween: 5,
        },
        960: {
          slidesPerView: 5,
          spaceBetween: 5,
        },
        1280: {
          slidesPerView: 6,
          spaceBetween: 5,
        },
        1500: {
          slidesPerView: 7,
          spaceBetween: 5,
        },
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
    }

    function getTeamImage(category, name) {
      if (category === undefined || (!(category in prosports))) { // && !(category in soccer))) {
        return ''
      }
      let imagePath = ''

      if (category in prosports) {
        const { divisions } = prosports[category]
        Object.keys(divisions).forEach(division => {
          const teamsInDivision = divisions[division].teams
          const x = teamsInDivision.filter(t => t.name.toLowerCase() === name)
          if (x.length > 0) {
            imagePath = x[0].logo
          }
        })
      }

      // if (category in soccer) {
      //   const { divisions } = soccer[category]
      //   Object.keys(divisions).forEach(division => {
      //     const teamsInDivision = divisions[division].teams
      //     const x = teamsInDivision.filter(t => t.name.toLowerCase() === name)
      //     if (x.length > 0) {
      //       imagePath = x[0].image
      //     }
      //   })
      // }

      return imagePath
    }

    const filteredPosts = computed(() => {
      if (filterSport.value == null) {
        return props.posts
      }
      const items = props.posts
      const results = []
      Object.keys(items).forEach(key => {
        if (key !== undefined) {
          const post = items[key]
          // if (post.categories.some(categories => categories === filterTeam.value)) {
          if (post.sport === filterSport.value) {
            results.push(post)
          }
        }
      })
      return results
    })

    function selectSport(sport) {
      filterSport.value = sport.toLowerCase()
    }

    return {
      uuid,
      swiperOptions,
      favoriteTeams,
      getTeamImage,
      selectSport,
      filterSport,
      filteredPosts,

      // Sports menu items
      sportMenuItems,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/_variables';
.post-sport-composite {
  background-color: rgb(233, 233, 233);
  padding: 5px 5px 5px 5px;
}

.wrapper {
    margin: 1em 0 0 0;
    position:relative;
    padding-left:2em; /* line-height of .wrapper div:first-child span */
    min-height: 250px;
}
.wrapper header {
    display:block;
    position:absolute;
    background: $primary;
    top:0;
    left:0;
    bottom:0;
    width:4em; /* line-height of .wrapper div:first-child span */
    overflow:hidden;
    white-space:nowrap;
}
.wrapper header h1 {
    -moz-transform-origin:0 50%;
    -moz-transform:rotate(-90deg) translate(-50%, 50%);
    -webkit-transform-origin:0 50%;
    -webkit-transform:rotate(-90deg) translate(-50%, 50%);
    -o-transform-origin:0 50%;
    -o-transform:rotate(-90deg) translate(-50%, 50%);
    -ms-transform-origin:0 50%;
    -ms-transform:rotate(-90deg) translate(-50%, 50%);
    transform-origin:0 50%;
    transform:rotate(-90deg) translate(-50%, 50%);
    position:absolute;
    top:0;
    bottom:0;
    height:4em; /* line-height of .wrapper div:first-child span */
    margin:auto;
    font-weight:bold;
    font-size:2em;
    line-height:2em; /* Copy to other locations */
    color: white;
}
.contents {
  padding: 10px 0 0 35px;
}

.more-sports-link {
  width: 100%;
  color: #352a81;
  font-size: 24px;
  text-transform: uppercase;
  font-weight: 800;
  text-align: center;
  margin: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
</style>
