<template>
  <div style="width: 100%;">
    <swiper
      :options="swiperOptions"
      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
    >

      <swiper-slide
        v-for="(game, index) in games"
        :key="index"
        style="flex-shrink: unset;"
      >
        <div
          v-if="!Array.isArray(game)"
          class="scoreboard"
          :class="game.status"
        >

          <router-link :to="{ name: 'posts-team-compare', path: `/teams/${game.sport}/${game.away.nickname}/${game.home.nickname}`, params: { sport: game.sport, away: game.away.nickname, home: game.home.nickname, game: JSON.stringify(game), } }">

          <div
            class="team gsw"
            :class="{'win': game.away.score > game.home.score}"
          >
            <!-- <div class="rank">1</div> -->
            <img
              class="logo"
              data-lazyload="true"
              :data-src="game.away.logo"
              alt=""
              :src="game.away.logo"
            >
            <div class="name">
              {{ game.away.code }}
            </div>
            <div class="score">
              {{ game.away.score }}
            </div>
          </div>

          <div class="team-divider">
            <p>{{ game.statustxt }}</p>
          </div>

          <div
            class="team utc"
            :class="{'win': game.home.score > game.away.score}"
          >
            <!-- <div class="rank">8</div> -->
            <img
              class="logo"
              data-lazyload="true"
              :data-src="game.home.logo"
              alt=""
              :src="game.home.logo"
            >
            <div class="name">
              {{ game.home.code }}
            </div>
            <div class="score">
              {{ game.home.score }}
            </div>
          </div>
        </router-link>
        </div>

        <div
          v-else-if="Array.isArray(game)"
          class="score_section"
        >
          <h3>{{ game[0] }}</h3>
        </div>
      </swiper-slide>

      <!-- If we need pagination -->
      <div
        slot="pagination"
        class="swiper-pagination"
        style="position: unset; margin-bottom: 5px;"
      />
    </swiper>
  </div>
</template>

<script>

// import style
import 'swiper/swiper-bundle.min.css'

export default {
  components: {},

  props: {
    games: {
      type: Array,
      default: () => [],
    },
  },

  setup() {
    const swiperOptions = {
      freeMode: true,
      slidesPerView: 10,
      // slidesPerGroup: 1,
      spaceBetween: 5,
      // loop: true,
      // loopFillGroupWithBlank: true,
      // Responsive breakpoints
      breakpoints: {
        // when window width is >= 0px
        0: {
          slidesPerView: 3,
          spaceBetween: 5,
        },
        480: {
          slidesPerView: 4,
          spaceBetween: 5,
        },
        640: {
          slidesPerView: 5,
          spaceBetween: 5,
        },
        960: {
          slidesPerView: 7,
          spaceBetween: 5,
        },
        1280: {
          slidesPerView: 8,
          spaceBetween: 5,
        },
        1500: {
          slidesPerView: 10,
          spaceBetween: 5,
        },
      },
    }

    return {
      swiperOptions,
    }
  },
}
</script>

<style lang="scss" scoped>
$color1: #8AB1AA;
$color2: #5EACC2;
$color3: #1F81AF;
$color4: #4B4452;
$color5: #46404f;
$color6: #746f7f;
$radius: 0px;

$primary: #352a81  !default;
$secondary: #fcb737 !default;

.scoreboard {
  min-width: 125px;
  height: 80px;
  border-radius: $radius;
  border: 1px solid white;
  box-shadow: 2px 3px 5px 0px rgba(0,0,0,0.25);
  background: linear-gradient(-60deg, #271a7a 53%, #251b63 53%);
}

.tbd {
  background: linear-gradient(-60deg, #271a7a 53%, #251b63 53%);
}

.live {
  background: linear-gradient(-60deg, #1a5c7a 53%, #1b4c63 53%);
}

.final {
  background: linear-gradient(-60deg, #787879 53%, #535353 53%);
}

.score_section {
  min-width: 50px;
  width: 50px;
  height: 80px;
  border-radius: $radius;
  border: 1px solid white;
  color: white;
  background: linear-gradient(-60deg, hsl(34, 100%, 38%) 53%, #ff9306 53%);
  box-shadow: 2px 3px 5px 0px rgba(0,0,0,0.25);
  margin-left: 10px;
}

.score_section h3 {
  -moz-transform-origin:0 50%;
  -moz-transform:rotate(-90deg) translate(-50%, 50%);
  -webkit-transform-origin:0 50%;
  -webkit-transform:rotate(-90deg) translate(-50%, 50%);
  -o-transform-origin:0 50%;
  -o-transform:rotate(-90deg) translate(-50%, 50%);
  -ms-transform-origin:0 50%;
  -ms-transform:rotate(-90deg) translate(-50%, 50%);
  transform-origin:0 50%;
  transform:rotate(-90deg) translate(-50%, 50%);
  position:absolute;
  top:0;
  bottom:0;
  height:2em; /* line-height of .wrapper div:first-child span */
  margin:auto;
  font-weight:bold;
  font-size:1.7em;
  line-height:2em; /* Copy to other locations */
  text-transform: uppercase;
  color: white;
}

.team {
  width: 100%;
  height: 50%;
  display: flex;
  align-items: center;
  font-size: 12pt;
  color: white;

  .name {
    flex-shrink: 0;
    width: 35px;
    display: flex;
    justify-content: center;
    letter-spacing: 2px;
  }

  &:nth-child(1) {
    .rank {
      border-top-left-radius: $radius;
    }
  }

  &:nth-child(3) {
    .rank {
      border-bottom-left-radius: $radius;
    }
  }

  .rank {
    height: 100%;
    width: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14pt;
    font-weight: bold;
    flex-shrink: 0;
  }

  .logo {
    width: 20px;
    margin: 10px;
  }

  .score {
    flex-shrink: 0;
    width: 35px;
    display: flex;
    justify-content: center;
    margin: 5px;
    letter-spacing: 2px;
  }

  .record {
    font-size: 12pt;
    color: $color6;
    padding: 1px;
    margin-right: 20px;
  }
}

.team-divider {
  // &::before {
  //   content: "";
  //   display: inline-block;
  //   position: absolute;
  //   height: 1px;
  //   width: 180px;
  //   background-color: $color6;
  // }

  // &::after {
  //   content: "";
  //   display: inline-block;
  //   position: absolute;
  //   height: 1px;
  //   width: 80px;
  //   right: 0;
  //   top: 0;
  //   background-color: $color6;
  // }

  p {
    position: absolute;
    font-weight: bold;
    left: -20px;
    top: -10px;
    font-size: 7pt;
  }

  height: 1px;
  width: 310px;
  position: absolute;
  margin-left: 40px;
  font-size: 10pt;
  color: white;
}

.gsw {
  $team-color: #EABF4F;
  .rank {
    background-color: $team-color;
    color: $color4;
  }
  &.win {
    .score {
      color: $team-color;
      font-weight: bold;
    }
    .record {
      color: white;
      border-bottom: 2px solid $team-color;
    }
  }
}

.utc {
  $team-color: #EABF4F;
  .rank {
    background-color: $team-color;
  }
  &.win {
    .score {
      color: $team-color;
      font-weight: bold;
    }
    .record {
      color: white;
      border-bottom: 2px solid $team-color;
    }
  }
}

.subtitle {
  margin: 20px;
  color: #31283C;
}

b {
  font-weight: bold;
}

// ----> ///////////////////////////////////////////////////////////
// @media screen and (min-width: 0px) {
//   .rwd-table td:before {
//     display: none;
//   }
//   .rwd-table th,
//   .rwd-table td {
//     display: table-cell;
//     padding: .1em 0;
//   }
//   .rwd-table td:first-child {
//     padding-left: 0.5em;
//     padding-right: 0.5em;
//   }
//   .rwd-table td:nth-child(4) {
//     padding-left: 0.5em;
//     padding-right: 0.5em;
//   }
//   .rwd-table th,
//   .rwd-table td {
//     text-align: center;
//     //padding: 0.3em 0.3em 0.3em 0.3em  !important;
//   }
// }

// .score {
//   background: #fff;
//   height: 130px;
//   min-width: 120px;
//   border: 1px solid #cbccce;
//   overflow: hidden;
//   position: relative;
// }

// .score_competitors {
//   flex-grow: 1;
// }

// .score-item {
//   display: flex;
//   list-style: none;
//   margin-bottom: 4px;
//   position: relative;
//   width: 100%;
// }
</style>
