function createTeamNavRoutes(sport, teamList, size, style) {
  const routes = []
  teamList.forEach(team => {
    if (Object.keys(team).includes('logo')) {
      routes.push({
        title: team.name,
        route: `team-${sport}-${team.name.toLowerCase().replace(/\s/g, '')}`,
        icon: 'UserIcon',
        image: team.logo,
        imageSize: size,
        style,
        resource: 'Public',
        action: 'read',
      })
    }
  })
  return routes
}

function createNavRoutes(dict, config) {
  const navRoutes = []
  for (let i = 0; i < config.sports.length; i += 1) {
    if (Object.keys(dict).includes(config.sports[i].name)) {
      const sport = dict[config.sports[i].name]
      const children = []
      if (sport.createTeamNavRoutes && Object.keys(sport).includes('divisions')) {
        Object.keys(sport.divisions).forEach(subkey => {
          const division = sport.divisions[subkey]
          // const child = {
          //   title: division.title,
          //   icon: division.icon,
          //   iconSource: division.iconSource,
          //   resource: 'Public',
          //   action: 'read',
          //   childrenIconsOnly: true,
          //   children: createTeamNavigation(division.teams, sport.size, sport.style),
          // }
          // children.push(child)
          const teamNavRoutes = createTeamNavRoutes(sport.category, division.teams, sport.size, sport.style)
          children.push({
            childheader: division.title,
            resource: 'User',
            action: 'read',
          })
          teamNavRoutes.forEach(child => children.push(child))
        })
      }

      let route
      if (children.length > 0) {
        route = {
          title: sport.title,
          route: sport.route,
          icon: sport.icon,
          iconSource: sport.iconSource,
          // icon: 'UserIcon',
          // iconSource: 'sports',
          resource: 'Public',
          action: 'read',
          childrenIconsOnly: true,
          children,
        }
      } else {
        route = {
          title: sport.title,
          route: sport.route,
          icon: sport.icon,
          iconSource: sport.iconSource,
          // icon: 'UserIcon',
          // iconSource: 'sports',
          resource: 'Public',
          action: 'read',
        }
      }

      /* eslint-disable no-console */
      console.log(`createNavRoutes ---> ${sport.title}, ${sport.route}`)
      console.log(route)
      /* eslint-enable no-console */

      navRoutes.push(route)
    }
  }

  return navRoutes
}

export default createNavRoutes
