<template>
  <b-card
    img-top
    no-body
    tag="article"
    style="max-width: 20rem; margin-bottom: 0.25rem !important;"
    class="rounded-0 overflow-hidden"
  >
    <b-link :to="getMoreDict(post)">
      <b-card-img
        v-image-fall-back
        :src="imageUrl"
        alt=" "
        class="image-tmp rounded-0 page-link-local"
        :style="'object-position: ' + post.imagePos + ';'"
      />
    </b-link>

    <b-card-text
      class="mini-post-title page-link-local"
      style="padding: 10px 10px 10px 10px;"
      :style="`font-size: 0.7rem`"
    >
      <div>
        <span
          v-for="(category, index) in post.categories"
          :key="index"
          class="mini-post-category"
        >
          <router-link
            :to="{ path: `/${category}` }"
            class="text-muted"
            style="margin-right: 0px; font-size: 0.7rem;"
          >
            {{ category }}
          </router-link>
        </span>
        <span
          v-if="post.team !== ''"
          style="padding: 0 5px 0 5px; display: inline-block; text-transform: uppercase;"
        >
          |
          <router-link
            :to="{ path: `/${post.categories[0]}/${post.team.replace(/\s/g, '')}` }"
            class="text-muted"
            style="margin-right: 5px; font-size: 0.7rem;"
          >
            {{ post.team }}
          </router-link>
        </span>
      </div>

      <b-link
        :to="getMoreDict(post)"
        class="mini-post-title"
      >
        {{ $filters.limitTo(post.title, titleLength) }}
      </b-link>
      <div
        class="blog-detail-share text-muted"
        style="padding: 10px 5px 0 5px;"
      >
        {{ formatDate(new Date(post.pubDate)) }}
      </div>
    </b-card-text>
  </b-card>
</template>

<script>
import {
  reactive, toRefs, computed,
} from '@vue/composition-api'

import {
  BCard,
  BCardText,
  BCardImg,
  BLink,
} from 'bootstrap-vue'

import formatDate from '../utils/formatDate'

export default {
  components: {
    // Card components
    BCard,
    BCardText,
    BCardImg,
    BLink,
  },

  props: {
    post: {
      type: Object,
      default: null,
    },
    collapsed: {
      type: Boolean,
      default: false,
    },
    minimal: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, context) {
    const event = reactive({
      getBaseUrl: context.root.$baseFrontendUrl,
      getBaseUrlWithPort: context.root.$baseFrontendUrlWithPort,
      isActive: false,
      imageError: false,
      goLink: '',
      titleLength: 64,
      descriptionLength: 96,
    })

    const handleClick = e => {
      let url = e.target.href

      if (url.endsWith('/')) {
        url = url.slice(0, url.length - 1)
      }

      if (e.button === 0) {
        window.open(url, '_blank')
      } else if (e.button === 1) {
        // Skip middle click, handled by browser.
      } else if (e.button === 2) {
        window.open(url, '_blank')
      }

      e.preventDefault()
    }

    function domainFromUrl(url) {
      let result = ''
      // eslint-disable-next-line
      const match = url.match(/^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n\?\=]+)/im)
      if (match != null) {
        [, result] = match
        // eslint-disable-next-line
        const newmatch = result.match(/^[^\.]+\.(.+\..+)$/)
        if (newmatch != null) {
          [, result] = newmatch
        }
      }
      return result
    }

    function urlhostname() {
      try {
        const urlObject = new URL(props.post.source)
        // return urlObject.hostname.replace(/^[^.]+\./g, '')
        return domainFromUrl(urlObject.hostname)
      } catch (error) {
        return 'newsapi'
      }
    }

    const imageUrl = computed(() => props.post.images[0])

    function getMoreDict(post) {
      if (post.categories !== undefined && post.categories.length > 0) {
        if (post.team !== undefined && post.team !== '') {
          return {
            name: 'post-detail-sport-team-slug',
            path: `/post/${post.categories[0]}/${post.team}/${post.slug}`,
            params: {
              team: post.team,
              sport: post.categories[0],
              slug: post.slug,
              post: JSON.stringify(post),
            },
          }
        }

        return {
          name: 'post-detail-sport-slug',
          path: `/post/${post.categories[0]}/${post.slug}`,
          params: {
            sport: post.categories[0],
            slug: post.slug,
            post: JSON.stringify(post),
          },
        }
      }

      return {
        name: 'post-detail-slug',
        path: `/post/${post.slug}`,
        params: {
          slug: post.slug,
          post: JSON.stringify(post),
        },
      }
    }

    return {
      ...toRefs(event),
      formatDate,
      handleClick,
      urlhostname,
      imageUrl,
      getMoreDict,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/_variables';
@import '@core/scss/vue/pages/page-blog.scss';

$border: #ebebeb;
$text: #444;
$catpad: 15px;

@mixin transition($args...) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}

.page-link-local, .page-link-local:visited, .page-link-local:hover, .page-link-local:active, .page-link-local:focus {
  outline: none !important;
}

.image-tmp {
  height: 100%;
  max-height: 90px;

  // Sets the maximum height to higher value when the card changes
  // from picture on top to picture on left side.
  @media screen and (max-width: 992px) {
    max-height: 90px;
  }

  overflow: hidden;
  transition: transform 0.5s ease;
  transition-property: transform;
  transition-duration: 0.5s;
  transition-timing-function: ease;
  transition-delay: 0s;
  backface-visibility: hidden;
  object-fit: cover;
}

.image-tmp:hover {
  transform: scale(1.1);
}

.mini-post-category {
  display: inline-block;
  text-transform: uppercase;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.0625rem;
  margin-bottom: 5px;

  @media screen and (max-width: 992px) {
    font-size: 0.7em;
  }
}

.mini-post-title {
  @include transition(color 0.3s ease);
  font-size: 0.9rem;
  line-height: 1.15;
  font-weight: 500;
  overflow: hidden;
  color: $primary;

  @media screen {
    height: 100%;
  }

  @media screen and (max-width: 992px) {
    font-size: 1.1em;
  }
}

.post-time {
  line-height: 1;
  z-index: 10;
  text-align: right;
  // position: sticky;
  // padding: 10px 0 0 0;
  position: absolute;
  bottom: 11px;
  right: 10px;
}

.rounded-card {
  border-radius: 5px;
}

a {
  text-decoration: none;
  color: #3498db;
}

</style>
