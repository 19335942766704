<template>
  <b-card>
    <h5>People of Interest</h5>

    <!-- user suggestion  -->
    <div
      v-for="(data, index) in filteredPeople"
      :key="data.name"
      class="d-flex justify-content-start align-items-center"
      :class="index == 0 ? 'mt-2' : 'mt-1'"
    >
      <b-avatar
        :src="data.avatar"
        class="mr-50"
        size="40"
      />
      <div class="user-page-info">
        <h6 class="mb-0">
          {{ data.name }}
        </h6>
        <small class="text-muted">[{{ data.sport }}] {{ data.born }}</small>
      </div>
      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        variant="primary"
        class="btn-icon ml-auto"
        size="sm"
      >
        <router-link
          class="nav-link"
          :to="`/person/${data.sport}/${data.path}`"
          style="color: white;"
        >
          <feather-icon icon="ArrowRightIcon" />
        </router-link>
      </b-button>
    </div>
    <!--/ user suggestion  -->
  </b-card>
</template>

<script>
import {
  computed,
} from '@vue/composition-api'

import {
  BCard, BAvatar, BButton,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BAvatar,
    BButton,
  },

  directives: {
    Ripple,
  },

  props: {
    people: {
      type: Array,
      default: () => [],
    },
    sport: {
      type: String,
      default: '',
    },
  },

  setup(props) {
    const filteredPeople = computed(() => {
      if (props.sport !== '') {
        return props.people.filter(person => (person.name !== undefined) && (person.name !== '') && (person.sport === props.sport))
      }
      return props.people.filter(person => (person.name !== undefined) && (person.name !== ''))
    })

    return {
      filteredPeople,
    }
  },
}
</script>
