<template>
  <b-card
    no-body
    class="overflow-hidden rounded-0 angled"
    style="margin-bottom: 0px; max-height: 300px;"
  >
    <h3
      v-if="title !== ''"
      class="tagbanner tagsizes"
    >
      {{ title }}
    </h3>

    <b-card-img
      :src="post.images[0]"
      class="image-tmp"
      :style="'object-position: ' + post.imagePos + ';'"
    />
    <div class="entry-content">
      <h4
        v-b-toggle="'imagepost-collapse-' + post.slug"
        class="entry-title"
      >
        {{ post.title }}
      </h4>

      <b-collapse
        :id="`imagepost-collapse-${post.slug}`"
        :visible="$store.getters['app/currentBreakPoint'] !== 'xs' && $store.getters['app/currentBreakPoint'] !== 'sm' && $store.getters['app/currentBreakPoint'] !== 'md'"
        class="entry-description"
      >
        {{ post.description }}
      </b-collapse>
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardImg,
  BCollapse,
  VBToggle,
} from 'bootstrap-vue'

import {
  reactive, toRefs, computed,
} from '@vue/composition-api'

export default {
  components: {
    // Card components
    BCard,
    BCardImg,
    BCollapse,
  },

  directives: {
    'b-toggle': VBToggle,
  },

  props: {
    post: {
      type: Object,
      default: null,
    },
    title: {
      type: String,
      default: '',
    },
  },

  setup(props, context) {
    const event = reactive({
      getBaseUrl: context.root.$baseFrontendUrl,
      isActive: false,
    })

    const canUpdate = computed(() => context.root.$can('update', props.post))

    const handleClick = e => {
      let url = e.target.href

      if (url.endsWith('/')) {
        url = url.slice(0, url.length - 1)
      }

      if (e.button === 0) {
        window.open(url, '_blank')
      } else if (e.button === 1) {
        // Skip middle click, handled by browser.
      } else if (e.button === 2) {
        window.open(url, '_blank')
      }

      e.preventDefault()
    }

    return {
      ...toRefs(event),
      handleClick,
      canUpdate,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/_variables';

$border-width: 15px;

.image-tmp {
  padding: $border-width;
  overflow: hidden;
  transition: transform 0.5s ease;
  transition-property: transform;
  transition-duration: 0.5s;
  transition-timing-function: ease;
  transition-delay: 0s;
  backface-visibility: hidden;
  object-fit: cover;
  // border-top: 20px solid $secondary;
  // border-right: 20px solid $secondary;
  // border-bottom: 20px solid $secondary;
  // border-left: 20px solid $secondary;
}

.entry-content {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.75);
  margin: 0px $border-width $border-width $border-width;
}

.entry-title {
  padding: 25px;
  font-size: 24px;
  color: $secondary;

  @media screen and (max-width: 992px) {
    font-size: 18px;
  }
}

.entry-description {
  padding: 0px 25px 25px 25px;
  margin-top: -10px;
  font-size: 20px;
  color: white;

  @media screen and (max-width: 992px) {
    font-size: 14px;
  }
}

.angled {
  background: linear-gradient(-105deg, $secondary 59%, $primary 60%);
}

.tagsizes {
  top: 10px;
  font-size: 36px;
  line-height: 44px;

  @media screen and (max-width: 992px) {
    top: 10px;
    font-size: 20px;
    line-height: 24px;
  }
}
</style>
