<template>
  <b-carousel
    id="carousel-interval"
    controls
    indicators
    :interval="4000"
    class="overflow-hidden rounded-0"
    style="margin-bottom: 0px;"
  >
    <a
      v-for="post in posts"
      :key="post.id"
    >
      <b-link :to="getMoreDict(post)">
        <b-carousel-slide
          :caption-html="displayPosts ? '' : `${getBaseCaption(post.title)}`"
          class="carousel-item"
        >
          <!-- <template v-slot:img>
          <b-card-group>
            <b-card :img-src="post.images[0]" overlay :style="`${getStyle()}`"></b-card>
            <b-card :img-src="post.images[0]" overlay :style="`${getStyle()}`"></b-card>
            <b-card :img-src="post.images[0]" overlay :style="`${getStyle()}`"></b-card>
          </b-card-group>
          </template> -->
          <template #img>
            <Post
              v-if="displayPosts"
              :post="post"
            />
            <template v-else>
              <img
                v-if="post.images.length > 0 && post.images[0] != null && post.images[0] != ''"
                v-image-fall-back
                class="d-block img-fluid"
                :src="post.images[0]"
                :style="`${getStyle()}`"
              >
              <img
                v-else
                class="d-block img-fluid"
                :src="require('@/assets/images/logo/slide-background.png')"
                :style="`${getStyle()}`"
              >
            </template>
          </template>
        </b-carousel-slide>
      </b-link>
    </a>
  </b-carousel>
</template>

<script>
import {
  reactive, toRefs,
} from '@vue/composition-api'

import {
  BCarousel,
  BCarouselSlide,
  // BCardGroup,
  // BCard,
  BLink,
} from 'bootstrap-vue'

import store from '@/store'
import Post from './Post.vue'

export default {
  components: {
    // Card components
    BCarousel,
    BCarouselSlide,
    Post,
    // BCardGroup,
    // BCard,
    BLink,
  },

  props: {
    posts: {
      type: Array,
      default: () => [],
      // validator: (prop) => prop.every(e => typeof e === 'string'),
    },
    displayPosts: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, context) {
    const event = reactive({
      getBaseUrl: context.root.$baseFrontendUrl,
    })

    function getMoreDict(post) {
      if (post.categories !== undefined && post.categories.length > 0) {
        if (post.team !== undefined && post.team !== '') {
          return {
            name: 'post-detail-sport-team-slug',
            path: `/post/${post.categories[0]}/${post.team}/${post.slug}`,
            params: {
              team: post.team,
              sport: post.categories[0],
              slug: post.slug,
              post: JSON.stringify(post),
            },
          }
        }

        return {
          name: 'post-detail-sport-slug',
          path: `/post/${post.categories[0]}/${post.slug}`,
          params: {
            sport: post.categories[0],
            slug: post.slug,
            post: JSON.stringify(post),
          },
        }
      }

      return {
        name: 'post-detail-slug',
        path: `/post/${post.slug}`,
        params: {
          slug: post.slug,
          post: JSON.stringify(post),
        },
      }
    }

    function getMoreHref(post) {
      if (post.categories !== undefined && post.categories.length > 0) {
        if (post.team !== undefined && post.team !== '') {
          return `/post/${post.categories[0]}/${post.team}/${post.slug}`
        }

        return `/post/${post.categories[0]}/${post.slug}`
      }

      return `/post/${post.slug}`
    }

    function getBaseCaption(text) {
      // :caption-html="$store.getters['app/currentBreakPoint'] === 'xs' ? `<span class='text-white' style='background-color: #000; font-size: 12px; opacity: 0.75; padding: 5px;'>${post.title}</span>`: `<span class='text-white' style='background-color: #000; font-size: 16px; opacity: 0.75; padding: 5px;'>${post.title}</span>`"
      if (store.getters['app/currentBreakPoint'] === 'lg' || store.getters['app/currentBreakPoint'] === 'xl') {
        return `<div style='background-color: #000; color: white; font-size: 16px; opacity: 0.75; padding: 5px;'>${text}</div>`
      }
      return `<div style='background-color: #000; color: white; font-size: 11px; opacity: 0.75; padding: 5px;'>${text}</div>`
    }

    function getCaption(text, post) {
      // :caption-html="$store.getters['app/currentBreakPoint'] === 'xs' ? `<span class='text-white' style='background-color: #000; font-size: 12px; opacity: 0.75; padding: 5px;'>${post.title}</span>`: `<span class='text-white' style='background-color: #000; font-size: 16px; opacity: 0.75; padding: 5px;'>${post.title}</span>`"
      if (store.getters['app/currentBreakPoint'] === 'lg' || store.getters['app/currentBreakPoint'] === 'xl') {
        return `<div style='background-color: #000; color: white; font-size: 16px; opacity: 0.75; padding: 5px;'>${text}</div> <a href="${getMoreHref(post)}" target="_self" style="margin-bottom: 10px;" class="btn btn-sm btn-secondary">More</a>`
      }
      return `<div style='background-color: #000; color: white; font-size: 11px; opacity: 0.75; padding: 5px;'>${text}</div> <a href="${getMoreHref(post)}" target="_self" style="margin-bottom: 0px;" class="btn btn-sm btn-secondary">More</a>`
    }

    function getStyle() {
      // "$store.getters['app/currentBreakPoint'] === 'xs' ? 'max-height: 200px; min-width: auto;':
      if (store.getters['app/currentBreakPoint'] === 'lg' || store.getters['app/currentBreakPoint'] === 'xl') {
        return 'height: 100vh !important; max-height: 420px; overflow: hidden; object-fit: cover; object-position: center center; padding: 0 5px 0 5px;'
      }
      return 'height: 100vh !important; max-height: 215px; overflow: hidden; object-fit: cover; object-position: center center; padding: 0 5px 0 5px;'
    }

    return {
      // getBaseUrl,
      ...toRefs(event),
      getCaption,
      getBaseCaption,
      getStyle,
      getMoreDict,
      getMoreHref,
    }
  },
}
</script>

<style lang="scss">
/* Forces image to be 100% width and not max width of 100% */
.carousel-item .img-fluid {
  width:100%;
}

// /* Anchors are inline so you need t0 make them block to go full width */
// .carousel-item a {
//   display: block;
//   width:100%;
// }

// .carousel-indicators li {
//   width: 10px;
//   height: 10px;
//   border-radius: 100%;
// }
// .carousel-indicators {
//   bottom: -50px;
// }

// .carousel-control-prev-icon,
// .carousel-control-next-icon {
//   height: 100px;
//   width: 100px;
//   outline: black;
//   background-size: 100%, 100%;
//   border-radius: 50%;
//   border: 10px solid black;
//   background-image: none;
// }

// .carousel-control-next-icon:after {
//   content: '>';
//   font-size: 55px;
//   color: red;
// }

// .carousel-control-prev-icon:after {
//   content: '<';
//   font-size: 55px;
//   color: red;
// }

// .carousel-control-prev-icon {
//  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
// }

// .carousel-control-next-icon {
//   background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
// }

.carousel-control-prev-icon {
  position: absolute;
  bottom: 15px;
  width: 25px;
  height: 25px;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ffffff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}
.carousel-control-next-icon {
  position: absolute;
  bottom: 15px;
  width: 25px;
  height: 25px;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ffffff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}
.carousel-indicators > li {
  // width: 8px;
  // height: 8px;
  // margin-left: 5px;
  // margin-right: 5px;
  background-color: #ffffff;
  // border-radius: 50%;
}
.carousel-indicators .active {
  background-color: #fcb737;
}
</style>
