export default {
  sports: [
    { name: 'nfl', logo: 'football' },
    { name: 'mlb', logo: 'baseball' },
    { name: 'nhl', logo: 'hockey' },
    { name: 'nba', logo: 'basketball' },
    { name: 'soccer', logo: 'soccer' },
    { name: 'ncaa', logo: 'star' },
    { name: 'tennis', logo: 'star' },
    { name: 'golf', logo: 'hockey' },
  ],
}
