<template>
  <div
    class="row match-height"
  >
    <h6
      v-if="divider !== ''"
      class="kf_hd2"
    >
      <span>
        <router-link
          class="nav-link"
          :to="`/${dividerPath}`"
          style="color: white;"
        >
          <b-img
            :src="require(`@/assets/images/icons/sports/${image}.svg`)"
            height="32px"
            style="padding-right: 10px; padding-bottom: 5px;"
          />
          <strong class="titleClass">{{ divider }}</strong>
        </router-link>
      </span>

      <div class="more-link">
        <router-link
          class="nav-link"
          :to="`/${dividerPath}`"
          style="color: white;"
        >
          {{ moreText }}
          <feather-icon
            icon="MoreHorizontalIcon"
            size="28"
          />
        </router-link>
      </div>
    </h6>

    <h6
      v-if="title !== ''"
      class="kf_hd1"
      style="margin-right: 5px; margin-left: 5px;"
    >
      <span>
        <strong class="titleClass">{{ title }}</strong>
        {{ titleExtra }}
        <div
          v-if="titleExtraSmall !== ''"
          class="text-center"
          style="font-size: 12px;"
        >
          {{ titleExtraSmall }}
        </div>
      </span>
    </h6>

    <!-- Remove the :style check for breakpoint - currently not doing anything. -->
    <b-col
      v-for="(post, index) in posts"
      :id="index"
      :key="`${uuid}-${post.id}-${name}-${index}`"
      :class="getPostClass(index)"
      :style="$store.getters['app/currentBreakPoint'] !== 'xs' ? 'padding-left: 5px; padding-right: 5px; margin-bottom: 10px;' : 'padding-left: 5px; padding-right: 5px; margin-bottom: 10px;'"
    >
      <tweet
        v-if="post.postType === 'tweet'"
        :post="post"
      />
      <news
        v-else-if="post.postType === 'news'"
        :post="post"
      />
      <youtube
        v-else-if="post.postType === 'youtube'"
        :post="post"
      />
      <advertisement
        v-else-if="post.postType === 'ad'"
        :post="post"
      />
      <template v-else>
        <post-carousel
          v-if="carouselIndexes.includes(index)"
          :posts="posts.slice(index, index + 6)"
        />
        <!-- <poast
          v-else-if="index === 2"
          :post="post"
          :wide="true"
        /> -->
        <Poast
          v-else-if="!minimal && wideIndexes.includes(index)"
          :post="post"
          :wide="true"
        />
        <!-- Collapsed flag is dependent on the minimal property. If minimal is set then post should be collapsed. -->
        <Post
          v-else
          :post="post"
          :minimal="minimal"
          :collapsed="minimal"
        />
      </template>
    </b-col>
  </div>
</template>

<script>
import {
  BCol,
  BImg,
} from 'bootstrap-vue'

import {
  ref,
} from '@vue/composition-api'

import { v4 as uuidv4 } from 'uuid'

import Post from './Post.vue'
import Tweet from './Tweet.vue'
import Youtube from './Youtube.vue'
import News from './News.vue'
import Advertisement from './Advertisement.vue'
import Poast from './Poast.vue'
// import PostWide from './PostWide.vue'
import PostCarousel from './PostCarousel.vue'

export default {
  components: {
    // Card components
    BCol,
    BImg,

    // Post Types
    Post,
    Tweet,
    Youtube,
    News,
    Advertisement,
    Poast,
    // PostWide,
    PostCarousel,
  },

  props: {
    posts: {
      type: Array,
      default: () => [],
      // validator: (prop) => prop.every(e => typeof e === 'string'),
    },
    name: {
      type: String,
      default: '',
    },
    image: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    titleExtra: {
      type: String,
      default: '',
    },
    titleExtraSmall: {
      type: String,
      default: '',
    },
    divider: {
      type: String,
      default: '',
    },
    dividerPath: {
      type: String,
      default: '',
    },
    moreText: {
      type: String,
      default: '',
    },
    minimal: {
      type: Boolean,
      default: false,
    },
    carouselIndexes: {
      type: Array,
      default: () => [6, 23],
    },
    wideIndexes: {
      type: Array,
      default: () => [0, 6, 11, 30],
    },
    adIndexes: {
      type: Array,
      default: () => [9, 29],
    },
  },

  setup(props) {
    const uuid = ref(uuidv4())

    function getPostClass(index) {
      if (props.minimal) {
        return 'col-lg-12 col-md-12 col-sm-12 col-12'
      }
      return props.wideIndexes.includes(index) ? 'col-lg-8 col-md-8 col-sm-12 col-12' : 'col-lg-4 col-md-4 col-sm-6 col-12'
    }

    return {
      uuid,
      getPostClass,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/_variables';

.more-link {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  color: white;
}

</style>
